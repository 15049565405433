import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Lokalizacja } from 'src/app/modele/projekty/lokalizacje/lokalizacja';
import { PobierzLokalizacjeFiltr } from 'src/app/modele/projekty/lokalizacje/pobierzLokalizacjeFiltr';
import { LokalizacjaDoSpisu } from 'src/app/modele/projekty/spis/lokalizacjaDoSpisu';
import { PobierzLokalizacjeDoSpisuFiltr } from 'src/app/modele/projekty/spis/pobierzLokalizacjeDoSpisuFiltr';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Routing } from 'src/app/modele/wspolne/routing';
import {
  Sortowanie,
  KierunekSortowania,
} from 'src/app/modele/wspolne/sortowanie';
import { Stronicowanie } from 'src/app/modele/wspolne/stronicowanie';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EksportDanychService } from 'src/app/serwisy/projekty/eksport-danych.service';
import { LokalizacjeService } from 'src/app/serwisy/projekty/lokalizacje.service';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';
import { BazowyComponent } from '../../bazowy.component';
import { ImportLokalizacjiModalComponent } from '../lokalizacje/import-lokalizacji-modal/import-lokalizacji-modal.component';
import { Subscription } from 'rxjs';
import { PobierzSumySrodkowWLokalizacjachRezultat } from 'src/app/modele/projekty/lokalizacje/pobierzSumySrodkowWLokalizacjachRezultat';

@Component({
  selector: 'app-spis',
  templateUrl: './spis.component.html',
  styleUrls: ['./spis.component.css'],
})
export class SpisComponent extends BazowyComponent implements OnInit {
  idProjektu: string;
  czyZaladowanoDane: boolean;
  calkowitaLiczbaElementow: BigInteger;
  rozmiarTabeli: number = 25;
  czyJestWiecejRekordow: boolean = true;
  czyPokazacMenuSortowanie: boolean;
  wyszukiwanie: RejestrFiltrowanyRequest<PobierzLokalizacjeDoSpisuFiltr> =
    new RejestrFiltrowanyRequest<PobierzLokalizacjeDoSpisuFiltr>();
  lokalizacje: Array<LokalizacjaDoSpisu> = new Array();
  nazwaProjektu: string;
  czyPokazanoWyszukiwarkeMobile: boolean;
  subsription: Subscription | undefined;
  sumy: PobierzSumySrodkowWLokalizacjachRezultat;

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
  };
  modalReference: any;
  modalService: any;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public lokalizacjeSerwis: LokalizacjeService,
    public projektySerwis: ProjektyService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute,
    public eksportDanychSerwis: EksportDanychService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      this.wyszukiwanie.filtry = new PobierzLokalizacjeDoSpisuFiltr();
      this.wyszukiwanie.filtry.projektId = this.idProjektu;
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'lp',
        KierunekSortowania.asc
      );
      this.wyszukiwanie.stronicowanie = new Stronicowanie(false);
      this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
      this.wyszukiwanie.stronicowanie.strona = 1;

      this.pobierzSumySrodkow();
      this.pobierzDaneDoEkranu(true);
    });
  }

  pobierzSumySrodkow() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.lokalizacjeSerwis
      .pobierzSumySrodkowWLokalizacjach(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.sumy = rezultat.element;

          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzDaneDoEkranu(zLadowaczem: boolean) {
    this.subsription = this.lokalizacjeSerwis
      .pobierzLokalizacjeDoSpisu(this.wyszukiwanie, zLadowaczem)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          this.lokalizacje = rezultat.element.lokalizacje;
          this.calkowitaLiczbaElementow =
            rezultat.element.calkowitaIloscRekordow;
          this.czyJestWiecejRekordow = rezultat.element.czyJestWiecejRekordow;
          this.nazwaProjektu = rezultat.element.nazwaProjektu;

          this.czyZaladowanoDane = true;
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  eksportujDane() {
    var model = new IdModel();
    model.id = this.idProjektu;

    this.eksportDanychSerwis
      .eksportujSpisaneSrodkiProjektu(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          var a = document.createElement('a');
          a.target = '_blank';
          a.href =
            'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
            rezultat.element.plik;
          a.download = rezultat.element.nazwaPliku;
          a.click();
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zmienRozmiarTabeli() {
    this.wyszukiwanie.stronicowanie.iloscRekordow = this.rozmiarTabeli;
    this.pobierzDaneDoEkranu(true);
  }

  pobierzNumerRekorduPoczatek() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return this.wyszukiwanie.stronicowanie.rekordPoczatkowy + 1;
  }
  pobierzNumerRekorduKoniec() {
    if (this.wyszukiwanie.stronicowanie == undefined) {
      return 1;
    }
    return (
      this.wyszukiwanie.stronicowanie.rekordPoczatkowy + this.lokalizacje.length
    );
  }

  zmienStrone(oIle: number) {
    this.wyszukiwanie.stronicowanie.strona += oIle;
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy =
      (this.wyszukiwanie.stronicowanie.strona - 1) *
      this.wyszukiwanie.stronicowanie.iloscRekordow;
    this.pobierzDaneDoEkranu(true);
  }

  pokazMenuSortowania() {
    this.czyPokazacMenuSortowanie = !this.czyPokazacMenuSortowanie;
  }

  pobierzKierunekSortowania(kolumna: string) {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      return '';
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      return 'down';
    }
  }

  zmienKierunekSortowania(kolumna: string) {
    this.czyPokazacMenuSortowanie = false;
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki.find((m) => m.nazwaKolumny == kolumna);

    if (kierunek == undefined) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kolumna,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        'Id',
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }

  pobierzKolumneSortowania() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    switch (kierunki[0].nazwaKolumny) {
      case 'lp':
        return 'Lp.';
      case 'ulica':
        return 'Ulica';
      case 'miasto':
        return 'Miasto';
      case 'kodPocztowy':
        return 'Kod pocztowy';
      case 'numerZespolu':
        return 'Numer zespołu';
      case 'sumaSpisanychSrodkow':
        return 'Spisane środki';
      case 'spisaneSrodkiIpro':
        return 'Spisane IPRO';
      case 'srodkiZOswiadczen':
        return 'Oświadczenia';
      case 'sparowaneSrodki':
        return 'Sparowane';
      case 'srodkiZEwidencji':
        return 'Środki z ewidencji';
      case 'rodzajLokalizacji':
        return 'Rodzaj lokalizacji';
    }
  }

  pobierzKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    if (kierunki[0].kierunekSortowania == KierunekSortowania.asc) {
      return 'up';
    }
    if (kierunki[0].kierunekSortowania == KierunekSortowania.desc) {
      return '';
    }
  }

  zmienKierunekSortowaniaMobile() {
    var kierunki = this.wyszukiwanie.sortowanie.listaSortowania;

    var kierunek = kierunki[0];

    if (kierunek.kierunekSortowania == KierunekSortowania.asc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.desc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
    if (kierunek.kierunekSortowania == KierunekSortowania.desc) {
      this.wyszukiwanie.sortowanie = new Sortowanie(
        kierunek.nazwaKolumny,
        KierunekSortowania.asc
      );
      this.pobierzDaneDoEkranu(true);
      return;
    }
  }
  wyczyscSortowanieMobile() {
    this.wyszukiwanie.sortowanie = new Sortowanie('Id', KierunekSortowania.asc);
    this.pobierzDaneDoEkranu(true);
  }

  cofnij() {
    this.router.navigate([Routing.SzczegolyProjektu, this.idProjektu]);
  }

  przejdzDoOswiadczen() {
    this.router.navigate([Routing.OswiadczeniaCalegoProjektu, this.idProjektu]);
  }

  filtrujPoTekscie() {
    this.subsription?.unsubscribe();
    this.wyszukiwanie.stronicowanie.rekordPoczatkowy = 0;
    this.wyszukiwanie.stronicowanie.strona = 1;
    this.pobierzDaneDoEkranu(false);
  }

  czyPokazacWyczyscFiltry() {
    return (
      this.wyszukiwanie.filtry.lp ||
      this.wyszukiwanie.filtry.kodPocztowy ||
      this.wyszukiwanie.filtry.sparowaneSrodki ||
      this.wyszukiwanie.filtry.ulica ||
      this.wyszukiwanie.filtry.miasto ||
      this.wyszukiwanie.filtry.srodkiZEwidencji ||
      this.wyszukiwanie.filtry.sumaSpisanychSrodkow ||
      this.wyszukiwanie.filtry.numerZespolu ||
      this.wyszukiwanie.filtry.mpk ||
      this.wyszukiwanie.filtry.spisaneSrodkiIpro ||
      this.wyszukiwanie.filtry.srodkiZOswiadczen ||
      this.wyszukiwanie.filtry.rodzajLokalizacji
    );
  }

  wyczyscFiltry() {
    this.wyszukiwanie.filtry.lp = '';
    this.wyszukiwanie.filtry.kodPocztowy = '';
    this.wyszukiwanie.filtry.sparowaneSrodki = null;
    this.wyszukiwanie.filtry.ulica = '';
    this.wyszukiwanie.filtry.miasto = '';
    this.wyszukiwanie.filtry.srodkiZEwidencji = null;
    this.wyszukiwanie.filtry.numerZespolu = '';
    this.wyszukiwanie.filtry.sumaSpisanychSrodkow = null;
    this.wyszukiwanie.filtry.mpk = '';
    this.wyszukiwanie.filtry.spisaneSrodkiIpro = null;
    this.wyszukiwanie.filtry.srodkiZOswiadczen = null;
    this.wyszukiwanie.filtry.rodzajLokalizacji = '';

    this.pobierzDaneDoEkranu(true);
  }

  pokazWyszukiwarkeMobile(czyPokazac: boolean) {
    this.czyPokazanoWyszukiwarkeMobile = czyPokazac;
  }

  wybierzLokalizacje(id: string) {
    this.router.navigate([
      Routing.PomieszczeniaLokalizacji,
      this.idProjektu,
      id,
    ]);
  }
}
