export enum RodzajPolaSpisu {
  checkbox,
  data,
  liczbaCalkowita,
  liczbaZmiennoprzecinkowa,
  slownikJednokolumnowy,
  slownikWielokolumnowy,
  tekst,
  zdjecie,
  inne,
  kodQr,
  wieleNumerow,
}
