import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from './serwisy/ogolne/loader.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { KalendarzModalContent } from './komponenty/kalendarz/kalendarz-modal';
import { KalendarzService } from './serwisy/ogolne/kalendarz.service';
import { LogowanieComponent } from './komponenty/konto/logowanie/logowanie.component';
import { ZmianaHaslaEmailComponent } from './komponenty/konto/zmiana-hasla-email/zmiana-hasla-email.component';
import { ZmianaHaslaNoweHasloComponent } from './komponenty/konto/zmiana-hasla-nowe-haslo/zmiana-hasla-nowe-haslo.component';
import { NawigacjaComponent } from './komponenty/nawigacja/nawigacja.component';
import { NaglowekNonAuthComponent } from './komponenty/konto/naglowek-non-auth/naglowek-non-auth.component';
import { UstawieniaProfiluComponent } from './komponenty/konto/profil/ustawienia-profilu/ustawienia-profilu.component';
import { ZmianaEmailModalComponent } from './komponenty/konto/profil/zmiana-email-modal/zmiana-email-modal.component';
import { PotwierdzEmailComponent } from './komponenty/konto/profil/potwierdz-email/potwierdz-email.component';
import { TylkoPelneLiczbyDirective } from './dyrektywy/tylko-pelne-liczby.directive';
import { AuthService } from './serwisy/ogolne/auth.service';
import { MenuService } from './serwisy/ogolne/menu.service';
import { HttpRequestInterceptor } from './serwisy/ogolne/http-request.interceptor';
import { ErrorService } from './serwisy/ogolne/error.service';
import { AppInjectorModule } from './app.injector.module';
import { PotwierdzKontoComponent } from './komponenty/konto/potwierdz-konto/potwierdz-konto.component';
import { UzytkownicyComponent } from './komponenty/uzytkownicy/uzytkownicy/uzytkownicy.component';
import { NowyUzytkownikModalComponent } from './komponenty/uzytkownicy/nowy-uzytkownik-modal/nowy-uzytkownik-modal.component';
import { EdytujUzytkownikaModalComponent } from './komponenty/uzytkownicy/edytuj-uzytkownika-modal/edytuj-uzytkownika-modal.component';
import { DezaktywujUzytkownikaModalComponent } from './komponenty/uzytkownicy/dezaktywuj-uzytkownika-modal/dezaktywuj-uzytkownika-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ListaProjektowComponent } from './komponenty/projekty/lista-projektow/lista-projektow.component';
import { SzczegolyProjektuComponent } from './komponenty/projekty/szczegoly-projektu/szczegoly-projektu.component';
import { NowyProjektComponent } from './komponenty/projekty/nowy-projekt/nowy-projekt.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { EwidencjaComponent } from './komponenty/projekty/ewidencja/ewidencja.component';
import { EdytujProjektComponent } from './komponenty/projekty/edytuj-projekt/edytuj-projekt.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LokalizacjeComponent } from './komponenty/projekty/lokalizacje/lokalizacje.component';
import { SlownikiComponent } from './komponenty/projekty/slowniki/slowniki.component';
import { SlownikiDomyslneComponent } from './komponenty/administracja/slowniki-domyslne/slowniki-domyslne.component';
import { DodajSlownikModalComponent } from './komponenty/projekty/slowniki/dodaj-slownik-modal/dodaj-slownik-modal.component';
import { EdytujSlownikModalComponent } from './komponenty/projekty/slowniki/edytuj-slownik-modal/edytuj-slownik-modal.component';
import { TabelaSlownikaModalComponent } from './komponenty/projekty/slowniki/tabela-slownika-modal/tabela-slownika-modal.component';
import { ZespolyComponent } from './komponenty/projekty/zespoly/zespoly.component';
import { DodajZespolModalComponent } from './komponenty/projekty/zespoly/dodaj-zespol-modal/dodaj-zespol-modal.component';
import { EdytujZespolModalComponent } from './komponenty/projekty/zespoly/edytuj-zespol-modal/edytuj-zespol-modal.component';
import { TakNieModalComponent } from './komponenty/ogolne/tak-nie-modal/tak-nie-modal.component';
import { PolaSpisuComponent } from './komponenty/projekty/pola-spisu/pola-spisu.component';
import { SpisComponent } from './komponenty/projekty/spis/spis.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NowePoleModalComponent } from './komponenty/projekty/pola-spisu/nowe-pole-modal/nowe-pole-modal.component';
import { EdytujPoleModalComponent } from './komponenty/projekty/pola-spisu/edytuj-pole-modal/edytuj-pole-modal.component';
import { KonfiguracjaPomieszczenComponent } from './komponenty/projekty/konfiguracja-pomieszczen/konfiguracja-pomieszczen.component';
import { ImportLokalizacjiModalComponent } from './komponenty/projekty/lokalizacje/import-lokalizacji-modal/import-lokalizacji-modal.component';
import { ImportLokalizacjiBledyModalComponent } from './komponenty/projekty/lokalizacje/import-lokalizacji-bledy-modal/import-lokalizacji-bledy-modal.component';
import { MapujImportEwidencjiModalComponent } from './komponenty/projekty/ewidencja/mapuj-import-ewidencji-modal/mapuj-import-ewidencji-modal.component';
import { ImportEwidencjiBledyModalComponent } from './komponenty/projekty/ewidencja/import-ewidencji-bledy-modal/import-ewidencji-bledy-modal.component';
import { EdycjaMapowanEwidencjiModalComponent } from './komponenty/projekty/ewidencja/edycja-mapowan-ewidencji-modal/edycja-mapowan-ewidencji-modal.component';
import { PomieszczeniaLokalizacjiComponent } from './komponenty/projekty/spis/pomieszczenia-lokalizacji/pomieszczenia-lokalizacji.component';
import { SpisaneSrodkiLokalizacjiComponent } from './komponenty/projekty/spis/spisane-srodki-lokalizacji/spisane-srodki-lokalizacji.component';
import { NadwyzkiSrodkowLokalizacjiComponent } from './komponenty/projekty/spis/nadwyzki-srodkow-lokalizacji/nadwyzki-srodkow-lokalizacji.component';
import { NiedoborySrodkowLokalizacjiComponent } from './komponenty/projekty/spis/niedobory-srodkow-lokalizacji/niedobory-srodkow-lokalizacji.component';
import { DodajPomieszczenieModalComponent } from './komponenty/projekty/spis/dodaj-pomieszczenie-modal/dodaj-pomieszczenie-modal.component';
import { EdytujPomieszczenieModalComponent } from './komponenty/projekty/spis/edytuj-pomieszczenie-modal/edytuj-pomieszczenie-modal.component';
import { DodajKomentarzDoEwidencjiModalComponent } from './komponenty/projekty/ewidencja/dodaj-komentarz-do-ewidencji-modal/dodaj-komentarz-do-ewidencji-modal.component';
import { SpisaneSrodkiPomieszczeniaComponent } from './komponenty/projekty/spis/spisane-srodki-pomieszczenia/spisane-srodki-pomieszczenia.component';
import { NadwyzkiSrodkowPomieszczeniaComponent } from './komponenty/projekty/spis/nadwyzki-srodkow-pomieszczenia/nadwyzki-srodkow-pomieszczenia.component';
import { SpisSrodkaComponent } from './komponenty/projekty/spis/spis-srodka/spis-srodka.component';
import { EdycjaSrodkaComponent } from './komponenty/projekty/spis/edycja-srodka/edycja-srodka.component';
import { NgxMaskModule } from 'ngx-mask';
import { TylkoLiczbyZmiennoprzecinkoweDirective } from './dyrektywy/tylko-liczby-zmiennoprzecinkowe.directive';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TylkoRegexDirective } from './dyrektywy/tylko-regex.directive';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { ZnalezioneRekordyEwidencjiModalComponent } from './komponenty/projekty/spis/znalezione-rekordy-ewidencji-modal/znalezione-rekordy-ewidencji-modal.component';
import { WyborRaportuComponent } from './komponenty/projekty/spis/wybor-raportu/wybor-raportu.component';
import { GenerowanieArkuszaSpisuComponent } from './komponenty/projekty/spis/generowanie-arkusza-spisu/generowanie-arkusza-spisu.component';
import { IMaskModule } from 'angular-imask';
import { ParowanieSrodkowComponent } from './komponenty/projekty/spis/parowanie-srodkow/parowanie-srodkow.component';
import { SparowaneSrodkiModalComponent } from './komponenty/projekty/ewidencja/sparowane-srodki-modal/sparowane-srodki-modal.component';
import { UstawieniaComponent } from './komponenty/administracja/ustawienia/ustawienia.component';
import { ZmienHasloDoSkrzynkiModalComponent } from './komponenty/administracja/ustawienia/zmien-haslo-do-skrzynki-modal/zmien-haslo-do-skrzynki-modal.component';
import { KomentarzParowaniaModalComponent } from './komponenty/projekty/spis/parowanie-srodkow/komentarz-parowania-modal/komentarz-parowania-modal.component';
import { RodzajEksportuModalComponent } from './komponenty/projekty/spis/eksport-danych/rodzaj-eksportu-modal/rodzaj-eksportu-modal.component';
import { PrzeniesPomieszczeniaLokalizacjeModalComponent } from './komponenty/projekty/spis/przenies-pomieszczenia-lokalizacje-modal/przenies-pomieszczenia-lokalizacje-modal.component';
import { PrzeniesSrodkiPomieszczeniaModalComponent } from './komponenty/projekty/spis/przenies-srodki-pomieszczenia-modal/przenies-srodki-pomieszczenia-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { HistoriaZmianSrodkaModalComponent } from './komponenty/projekty/spis/historia-zmian-srodka-modal/historia-zmian-srodka-modal.component';
import { PrettyjsonPipe } from './pipes/prettyjson.pipe';
import { AutoryzacjaEmailComponent } from './komponenty/oswiadczenia/autoryzacja-email/autoryzacja-email.component';
import { AutoryzacjaKodComponent } from './komponenty/oswiadczenia/autoryzacja-kod/autoryzacja-kod.component';
import { OswiadczeniaComponent } from './komponenty/oswiadczenia/oswiadczenia/oswiadczenia.component';
import { InstrukcjaWypelnianiaOswiadczeniaComponent } from './komponenty/oswiadczenia/instrukcja-wypelniania-oswiadczenia/instrukcja-wypelniania-oswiadczenia.component';
import { DaneUzytkownikaOswiadczeniaComponent } from './komponenty/oswiadczenia/dane-uzytkownika-oswiadczenia/dane-uzytkownika-oswiadczenia.component';
import { EdytujOswiadczenieModalComponent } from './komponenty/oswiadczenia/edytuj-oswiadczenie-modal/edytuj-oswiadczenie-modal.component';
import { EdytujLokalizacjeModalComponent } from './komponenty/projekty/lokalizacje/edytuj-lokalizacje-modal/edytuj-lokalizacje-modal.component';
import { SrodkiZOswiadczenProjektuComponent } from './komponenty/projekty/spis/srodki-z-oswiadczen-projektu/srodki-z-oswiadczen-projektu.component';
import { InformacjeOPoluModalComponent } from './komponenty/projekty/spis/informacje-o-polu-modal/informacje-o-polu-modal.component';
import { ImportSrodkowModalComponent } from './komponenty/projekty/spis/import-srodkow-modal/import-srodkow-modal.component';
import { ImportSrodkowBledyModalComponent } from './komponenty/projekty/spis/import-srodkow-bledy-modal/import-srodkow-bledy-modal.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LogowanieComponent,
    ZmianaHaslaEmailComponent,
    ZmianaHaslaNoweHasloComponent,
    KalendarzModalContent,
    NawigacjaComponent,
    NaglowekNonAuthComponent,
    PotwierdzKontoComponent,
    UstawieniaProfiluComponent,
    ZmianaEmailModalComponent,
    PotwierdzEmailComponent,
    TylkoPelneLiczbyDirective,
    UzytkownicyComponent,
    NowyUzytkownikModalComponent,
    EdytujUzytkownikaModalComponent,
    DezaktywujUzytkownikaModalComponent,
    ListaProjektowComponent,
    SzczegolyProjektuComponent,
    NowyProjektComponent,
    EwidencjaComponent,
    EdytujProjektComponent,
    LokalizacjeComponent,
    SlownikiComponent,
    SlownikiDomyslneComponent,
    DodajSlownikModalComponent,
    EdytujSlownikModalComponent,
    TabelaSlownikaModalComponent,
    ZespolyComponent,
    DodajZespolModalComponent,
    EdytujZespolModalComponent,
    TakNieModalComponent,
    PolaSpisuComponent,
    SpisComponent,
    NowePoleModalComponent,
    EdytujPoleModalComponent,
    KonfiguracjaPomieszczenComponent,
    ImportLokalizacjiModalComponent,
    ImportLokalizacjiBledyModalComponent,
    MapujImportEwidencjiModalComponent,
    ImportEwidencjiBledyModalComponent,
    EdycjaMapowanEwidencjiModalComponent,
    PomieszczeniaLokalizacjiComponent,
    SpisaneSrodkiLokalizacjiComponent,
    NadwyzkiSrodkowLokalizacjiComponent,
    NiedoborySrodkowLokalizacjiComponent,
    DodajPomieszczenieModalComponent,
    EdytujPomieszczenieModalComponent,
    DodajKomentarzDoEwidencjiModalComponent,
    SpisaneSrodkiPomieszczeniaComponent,
    NadwyzkiSrodkowPomieszczeniaComponent,
    SpisSrodkaComponent,
    EdycjaSrodkaComponent,
    TylkoLiczbyZmiennoprzecinkoweDirective,
    TylkoRegexDirective,
    ZnalezioneRekordyEwidencjiModalComponent,
    WyborRaportuComponent,
    GenerowanieArkuszaSpisuComponent,
    ParowanieSrodkowComponent,
    SparowaneSrodkiModalComponent,
    UstawieniaComponent,
    ZmienHasloDoSkrzynkiModalComponent,
    KomentarzParowaniaModalComponent,
    RodzajEksportuModalComponent,
    PrzeniesPomieszczeniaLokalizacjeModalComponent,
    PrzeniesSrodkiPomieszczeniaModalComponent,
    HistoriaZmianSrodkaModalComponent,
    PrettyjsonPipe,
    AutoryzacjaEmailComponent,
    AutoryzacjaKodComponent,
    OswiadczeniaComponent,
    InstrukcjaWypelnianiaOswiadczeniaComponent,
    DaneUzytkownikaOswiadczeniaComponent,
    EdytujOswiadczenieModalComponent,
    EdytujLokalizacjeModalComponent,
    SrodkiZOswiadczenProjektuComponent,
    InformacjeOPoluModalComponent,
    ImportSrodkowModalComponent,
    ImportSrodkowBledyModalComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true,
      maxOpened: 1
    }),
    AppInjectorModule,
    NgSelectModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SlickCarouselModule,
    DragDropModule,
    NgxMaskModule.forRoot(),
    DigitOnlyModule,
    AutocompleteLibModule,
    NgImageFullscreenViewModule,
    IMaskModule
  ],
  providers: [{
    provide: ErrorHandler,
    useClass: ErrorService
  },
    CookieService,
    LoaderService,
    KalendarzService,
    AuthService,
    MenuService,
    NgbActiveModal,
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
