<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="text-left mb-2" [innerText]="informacjeOPoluSpisu"></div>
    <div class="input_container pb-3">
      <div style="width: 100%; display: flex; justify-content: center">
        <div class="tile-full">
          <div class="image-container" *ngIf="zdjecie">
            <img
              [src]="sciezkaPliku()"
              alt="Uploaded Image"
              class="uploaded-image"
              (click)="pokazGalerie()"
            />
          </div>
          <ng-image-fullscreen-view
            [images]="fullScreenZdjecia"
            imageIndex="0"
            [show]="pokazGalerieZdjec"
            (close)="zamknijGalerie()"
            [animationSpeed]="0.1"
          >
          </ng-image-fullscreen-view>
        </div>
      </div>
    </div>
  </div>
</div>
