<div class="modal-content" *ngIf="zaladowanoDane">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-2">
      Kopiowanie projektu {{ nazwaProjektu }}
    </div>
    <div class="text-center mb-2"></div>
    <form action="">
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('nazwaProjektu') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Nazwa projektu"
          name="nazwaProjektu"
          [(ngModel)]="model.nazwaProjektu"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('nazwaProjektu')"
        >
          {{ pobierzBladWalidacyjny("nazwaProjektu") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('nazwaKlienta') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="Nazwa klienta"
          name="nazwaKlienta"
          [(ngModel)]="model.nazwaKlienta"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('nazwaKlienta')"
        >
          {{ pobierzBladWalidacyjny("nazwaKlienta") }}
        </div>
      </div>
      <div
        class="input_container pb-3"
        [ngClass]="czyBladWalidacyjny('email') ? 'error' : ''"
      >
        <input
          type="text"
          placeholder="E-mail"
          name="email"
          [(ngModel)]="model.email"
        />
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('email')"
        >
          {{ pobierzBladWalidacyjny("email") }}
        </div>
      </div>

      <div
        class="input_container mb-3"
        [ngClass]="czyBladWalidacyjny('status') ? 'error' : ''"
      >
        <select name="" id="" [(ngModel)]="model.status" name="status">
          <option [ngValue]="undefined" [disabled]="true">Status</option>
          <option [ngValue]="'Przygotowanie'">Przygotowanie</option>
          <option [ngValue]="'Realizacja'">Realizacja</option>
          <option [ngValue]="'Zakonczony'">Zakończony</option>
          <option [ngValue]="'Zarchiwizowany'">Zarchiwizowany</option>
        </select>
        <div
          class="error-info fs__error pt-2"
          *ngIf="czyBladWalidacyjny('status')"
        >
          {{ pobierzBladWalidacyjny("status") }}
        </div>
      </div>

      <h3 class="mt-4 mb-4">Wybierz elementy do skopiowania</h3>

      <div class="chb por mb-3">
        <input
          id="lokalizacje"
          type="checkbox"
          [(ngModel)]="model.lokalizacje"
          name="lokalizacje"
          [disabled]="!czyElementDostepny('lokalizacje')"
          (change)="onCheckboxChange($event, 'lokalizacje')"
        />
        <label
          class="fs__chb"
          for="lokalizacje"
          [ngClass]="!czyElementDostepny('lokalizacje') ? 'disabled' : ''"
          >Lokalizacje</label
        >
      </div>

      <div class="chb por mb-3">
        <input
          id="ewidencja"
          type="checkbox"
          [(ngModel)]="model.ewidencja"
          name="ewidencja"
          [disabled]="!czyElementDostepny('ewidencja')"
          (change)="onCheckboxChange($event, 'ewidencja')"
        />
        <label
          class="fs__chb"
          for="ewidencja"
          [ngClass]="!czyElementDostepny('ewidencja') ? 'disabled' : ''"
          >Ewidencja</label
        >
      </div>

      <div class="chb por mb-3">
        <input
          id="komentarzeWEwidencji"
          type="checkbox"
          [(ngModel)]="model.komentarzeWEwidencji"
          name="komentarzeWEwidencji"
          [disabled]="!czyElementDostepny('komentarzeWEwidencji')"
          (change)="onCheckboxChange($event, 'komentarzeWEwidencji')"
        />
        <label
          class="fs__chb"
          for="komentarzeWEwidencji"
          [ngClass]="
            !czyElementDostepny('komentarzeWEwidencji') ? 'disabled' : ''
          "
          >Komentarze w ewidencji</label
        >
      </div>
      <div class="chb por mb-3">
        <input
          id="slowniki"
          type="checkbox"
          [(ngModel)]="model.slowniki"
          name="slowniki"
          [disabled]="!czyElementDostepny('slowniki')"
          (change)="onCheckboxChange($event, 'slowniki')"
        />
        <label
          class="fs__chb"
          for="slowniki"
          [ngClass]="!czyElementDostepny('slowniki') ? 'disabled' : ''"
          >Słowniki</label
        >
      </div>
      <div class="chb por mb-3">
        <input
          id="zespoly"
          type="checkbox"
          [(ngModel)]="model.zespoly"
          name="zespoly"
          [disabled]="!czyElementDostepny('zespoly')"
          (change)="onCheckboxChange($event, 'zespoly')"
        />
        <label class="fs__chb" for="zespoly">Zespoły</label>
      </div>
      <div class="chb por mb-3">
        <input
          id="polaSpisu"
          type="checkbox"
          [(ngModel)]="model.polaSpisu"
          name="polaSpisu"
          [disabled]="!czyElementDostepny('polaSpisu')"
          (change)="onCheckboxChange($event, 'polaSpisu')"
        />
        <label
          class="fs__chb"
          for="polaSpisu"
          [ngClass]="!czyElementDostepny('polaSpisu') ? 'disabled' : ''"
          >Pola spisu</label
        >
      </div>
      <div class="chb por mb-3">
        <input
          id="polaPomieszczen"
          type="checkbox"
          [(ngModel)]="model.polaPomieszczen"
          name="polaPomieszczen"
          [disabled]="!czyElementDostepny('polaPomieszczen')"
          (change)="onCheckboxChange($event, 'polaPomieszczen')"
        />
        <label
          class="fs__chb"
          for="polaPomieszczen"
          [ngClass]="!czyElementDostepny('polaPomieszczen') ? 'disabled' : ''"
          >Pola pomieszczeń</label
        >
      </div>
      <div class="chb por mb-3">
        <input
          id="mapowania"
          type="checkbox"
          [(ngModel)]="model.mapowania"
          name="mapowania"
          [disabled]="!czyElementDostepny('mapowania')"
          (change)="onCheckboxChange($event, 'mapowania')"
        />
        <label
          class="fs__chb"
          for="mapowania"
          [ngClass]="!czyElementDostepny('mapowania') ? 'disabled' : ''"
          >Mapowania</label
        >
      </div>

      <div
        class="error-info fs__error pt-2"
        *ngIf="czyBladWalidacyjny('wybraneElementy')"
      >
        {{ pobierzBladWalidacyjny("wybraneElementy") }}
      </div>

      <div class="input_container pb-3 mt-3">
        <button class="btn__primary" (click)="zapisz()">Zapisz</button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          Anuluj
        </button>
      </div>
    </form>
  </div>
</div>
