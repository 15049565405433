import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrzygotujDaneDoEksportuRezultat } from 'src/app/modele/projekty/eksportDanych/przygotujDaneDoEksportuRezultat';
import { EksportujDaneModel } from 'src/app/modele/projekty/spis/eksportujDaneModel';
import { PobierzSpisaneSrodkiLokalizacjiFiltry } from 'src/app/modele/projekty/spis/pobierzSpisaneSrodkiLokalizacjiFiltry';
import { SpisaneSrodkiLokalizacji } from 'src/app/modele/projekty/spis/spisaneSrodkiLokalizacji';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { PojedynczyElementResponse } from 'src/app/modele/wspolne/pojedynczyElement';
import { RejestrFiltrowanyRequest } from 'src/app/modele/wspolne/rejestrFiltrowanyRequest';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { SerwisBazowy } from '../bazowy.service';
import { AuthService } from '../ogolne/auth.service';
import { ErrorService } from '../ogolne/error.service';
import { LoadingService } from '../ogolne/loading.service';

@Injectable({
  providedIn: 'root',
})
export class EksportDanychService extends SerwisBazowy {
  constructor(
    httpClient: HttpClient,
    authService: AuthService,
    errorService: ErrorService,
    loaderService: LoadingService,
    translate: TranslateService
  ) {
    super(httpClient, authService, errorService, loaderService, translate);
  }

  public eksportujSpisaneSrodkiProjektu(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<Zalacznik>>(
      this.AdresApi + 'EksportujDane/EksportujSpisaneSrodkiProjektu',
      model,
      15 * 60 * 1000
    );
  }

  public eksportujSpisaneSrodkiLokalizacji(model: EksportujDaneModel) {
    return this.wykonajAkcje<
      EksportujDaneModel,
      PojedynczyElementResponse<Zalacznik>
    >(
      this.AdresApi + 'EksportujDane/eksportujSpisaneSrodkiLokalizacji',
      model,
      15 * 60 * 1000
    );
  }

  public eksportujNadwyzki(model: EksportujDaneModel) {
    return this.wykonajAkcje<
      EksportujDaneModel,
      PojedynczyElementResponse<Zalacznik>
    >(this.AdresApi + 'EksportujDane/eksportujNadwyzki', model, 15 * 60 * 1000);
  }

  public eksportujNiedobory(model: EksportujDaneModel) {
    return this.wykonajAkcje<
      EksportujDaneModel,
      PojedynczyElementResponse<Zalacznik>
    >(
      this.AdresApi + 'EksportujDane/eksportujNiedobory',
      model,
      15 * 60 * 1000
    );
  }

  przygotujDaneDoZrzucenia(model: IdModel) {
    return this.wykonajAkcje<
      IdModel,
      PojedynczyElementResponse<PrzygotujDaneDoEksportuRezultat>
    >(
      this.AdresApi + 'EksportujDane/PrzygotujDaneDoZrzucenia',
      model,
      15 * 60 * 1000
    );
  }

  pobierzWyeksportowaneDaneProjektu(model: IdModel) {
    return this.pobierzStream(
      this.AdresApi +
        'EksportujDane/PobierzWyeksportowaneDaneProjektu?id=' +
        model.id
    );
  }

  eksportujEwidencje(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<Zalacznik>>(
      this.AdresApi + 'EksportujDane/eksportujEwidencje',
      model,
      15 * 60 * 1000
    );
  }

  public eksportujOswiadczeniaProjektu(model: IdModel) {
    return this.wykonajAkcje<IdModel, PojedynczyElementResponse<Zalacznik>>(
      this.AdresApi + 'EksportujDane/EksportujOswiadczeniaProjektu',
      model,
      15 * 60 * 1000
    );
  }
}
