<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="ft__01 text-center py-4">Import środków</div>
    <div class="text-center mb-2"></div>
    <form action="">
      <div>
        <label
          class="btn__border pointer mb-4"
          for="file-upload"
          *ngIf="!zalacznik"
          style="display: grid; align-items: center"
          >Wybierz plik</label
        >
        <input
          id="file-upload"
          type="file"
          (change)="wybierzZalacznikZDysku($event)"
          hidden
        />

        <div
          class="d-flex justify-content-between align-items-center fs__imported mb-4 mt-4"
          *ngIf="zalacznik"
        >
          <div class="pt-1">{{ zalacznik.nazwaPliku }}</div>
          <div>
            <button class="btn__delete" (click)="usunZalacznik()"></button>
          </div>
        </div>
      </div>

      <div class="input_container pb-3">
        <button class="btn__primary" (click)="zapisz()">Zapisz</button>
      </div>
      <div class="input_container pb-3">
        <button class="btn__link" (click)="activeModal.dismiss()">
          Anuluj
        </button>
      </div>
    </form>
  </div>
</div>
