import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { ImportSrodkowModel } from 'src/app/modele/projekty/spis/importSrodkowModel';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SpisService } from 'src/app/serwisy/projekty/spis.service';
import { ImportSrodkowBledyModalComponent } from '../import-srodkow-bledy-modal/import-srodkow-bledy-modal.component';

@Component({
  selector: 'app-import-srodkow-modal',
  templateUrl: './import-srodkow-modal.component.html',
  styleUrls: ['./import-srodkow-modal.component.css'],
})
export class ImportSrodkowModalComponent
  extends BazowyComponent
  implements OnInit
{
  idProjektu: string;
  idPomieszczenia: string;
  model: ImportSrodkowModel = new ImportSrodkowModel();
  zalacznik: Zalacznik;

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public spisSerwis: SpisService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {});
  }

  usunZalacznik() {
    this.zalacznik = undefined;
  }

  wybierzZalacznikZDysku(event: any) {
    var plik = event.target.files[0];
    this.wybierzZalacznik(plik);

    event.target.value = null;
    return false;
  }

  wybierzZalacznik(plik: File) {
    if (plik) {
      var reader = new FileReader();
      reader.readAsDataURL(plik);
      reader.onload = () => {
        var zalacznik = new Zalacznik();
        zalacznik.nazwaPliku = plik.name;
        zalacznik.plik = this.usunZbedneZnakiZPliku(reader.result.toString());
        if (zalacznik.plik == 'data:') {
          return;
        }
        zalacznik.dataModyfikacji = new Date(plik.lastModified);
        zalacznik.format = plik.name.split('.').pop().toLowerCase();

        this.zalacznik = zalacznik;
      };
    }
  }

  usunZbedneZnakiZPliku(base64: string) {
    if (base64.includes(',')) {
      return base64.substring(base64.indexOf(',') + 1);
    }
    return base64;
  }

  zapisz() {
    this.model.zalacznik = this.zalacznik;
    this.model.idProjektu = this.idProjektu;
    this.model.idPomieszczenia = this.idPomieszczenia;
    this.spisSerwis.importujSrodki(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        if (this.czySaWynikiNaLiscie(rezultat.element.listaBledow)) {
          this.pokazModalZBledami(rezultat.element.listaBledow);
          return;
        }

        this.wyswietlKomunikat('Środki zostały zaimportowane');
        this.activeModal.close(true);
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  pokazModalZBledami(bledy: Array<string>) {
    this.modalReference = this.modalService.open(
      ImportSrodkowBledyModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.listaBledow = bledy;
  }
}
