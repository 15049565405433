import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-import-srodkow-bledy-modal',
  templateUrl: './import-srodkow-bledy-modal.component.html',
  styleUrls: ['./import-srodkow-bledy-modal.component.css'],
})
export class ImportSrodkowBledyModalComponent implements OnInit {
  listaBledow: Array<string> = new Array();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    setTimeout(() => {});
  }
}
