export class KopiujProjektModel {
  idProjektu: string;
  nazwaProjektu: string;
  nazwaKlienta: string;
  email: string;
  status: string;

  lokalizacje: boolean;
  ewidencja: boolean;
  komentarzeWEwidencji: boolean;
  slowniki: boolean;
  zespoly: boolean;
  polaSpisu: boolean;
  polaPomieszczen: boolean;
  mapowania: boolean;
}
