import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PolaSpisuProjektu } from 'src/app/modele/projekty/polaSpisu/polaSpisuProjektu';
import { RodzajPolaSpisu } from 'src/app/modele/projekty/polaSpisu/rodzajPolaSpisu';
import { ZmienKolejnoscPolSpisuModel } from 'src/app/modele/projekty/polaSpisu/zmienKolejnoscPolSpisuModel';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { PolaSpisuService } from 'src/app/serwisy/projekty/pola-spisu.service';
import { BazowyComponent } from '../../bazowy.component';
import { TakNieModalComponent } from '../../ogolne/tak-nie-modal/tak-nie-modal.component';
import { EdycjaMapowanEwidencjiModalComponent } from '../ewidencja/edycja-mapowan-ewidencji-modal/edycja-mapowan-ewidencji-modal.component';
import { EdytujPoleModalComponent } from './edytuj-pole-modal/edytuj-pole-modal.component';
import { NowePoleModalComponent } from './nowe-pole-modal/nowe-pole-modal.component';

@Component({
  selector: 'app-pola-spisu',
  templateUrl: './pola-spisu.component.html',
  styleUrls: ['./pola-spisu.component.css'],
})
export class PolaSpisuComponent extends BazowyComponent implements OnInit {
  idProjektu: string;
  czyZaladowanoDane: boolean;
  dane: PolaSpisuProjektu = new PolaSpisuProjektu();

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
  };
  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public polaSpisuSerwis: PolaSpisuService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idProjektu;

    this.polaSpisuSerwis.pobierzPola(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.dane = rezultat.element;

        this.czyZaladowanoDane = true;

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  dodajPole() {
    this.modalReference = this.modalService.open(
      NowePoleModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu();
        }
      },
      (onRejected) => {}
    );
  }

  cofnij() {
    this.router.navigate([Routing.Zespoly, this.idProjektu]);
  }

  pobierzRodzajPola(rodzajPola: RodzajPolaSpisu) {
    switch (rodzajPola) {
      case RodzajPolaSpisu.checkbox:
        return 'Checkbox';
      case RodzajPolaSpisu.data:
        return 'Data';
      case RodzajPolaSpisu.inne:
        return 'Inne';
      case RodzajPolaSpisu.liczbaCalkowita:
        return 'Liczba całkowita';
      case RodzajPolaSpisu.liczbaZmiennoprzecinkowa:
        return 'Liczba zmiennoprzecinkowa';
      case RodzajPolaSpisu.slownikJednokolumnowy:
        return 'Słownik jednokolumnowy';
      case RodzajPolaSpisu.slownikWielokolumnowy:
        return 'Słownik wielokolumnowy';
      case RodzajPolaSpisu.tekst:
        return 'Tekst';
      case RodzajPolaSpisu.zdjecie:
        return 'Zdjęcie';
      case RodzajPolaSpisu.kodQr:
        return 'Kod QR';
      case RodzajPolaSpisu.wieleNumerow:
        return 'Wiele numerów';
    }
  }

  edytujPole(id: string) {
    this.modalReference = this.modalService.open(
      EdytujPoleModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idPola = id;
    this.modalReference.result.then(
      (result) => {
        if (result) {
          this.pobierzDaneDoEkranu();
        }
      },
      (onRejected) => {}
    );
  }

  usunPole(id: string) {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.pytanie =
      'Czy na pewno chcesz usunąć pole spisu?';
    this.modalReference.result.then(
      (result) => {
        if (result) {
          var model = new IdModel();
          model.id = id;

          this.polaSpisuSerwis.usunPole(model).subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat('Pole zostało usunięte');
              this.pobierzDaneDoEkranu();
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }

  zmienKolejnosc(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      if (event.previousIndex == event.currentIndex) {
        return;
      }
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      var model = new ZmienKolejnoscPolSpisuModel();
      model.idProjektu = this.idProjektu;

      this.dane.pola.forEach((projekt) => {
        model.nowaKolejnosc.push(projekt.id);
      });

      this.polaSpisuSerwis.zmienKolejnosc(model).subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  edytujMapowania() {
    this.modalReference = this.modalService.open(
      EdycjaMapowanEwidencjiModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;
  }
}
