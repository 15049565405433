<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-4">{{ nazwaProjektu }}</div>

    <div class="d-flex pt-3">
      <div class="project__menu" *ngIf="!czyPracownikKlienta()">
        <a routerLink="/project-details/{{ idProjektu }}">Projekt</a>
        <a class="active" routerLink="/inventory/{{ idProjektu }}">Spis</a>
        <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
        <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
        <a
          routerLink="/project-dictionaries/{{ idProjektu }}"
          *ngIf="czyAdmin()"
          >Słowniki</a
        >
        <a routerLink="/teams/{{ idProjektu }}" *ngIf="czyAdmin()">Zespoły</a>
        <a routerLink="/inventory-fields/{{ idProjektu }}" *ngIf="czyAdmin()"
          >Pola spisu</a
        >
      </div>
    </div>
  </div>

  <div class="container-fluid d-none d-md-block pt-4">
    <div class="box__scroll py-3">
      <div
        class="box__scroll--top d-flex justify-content-between align-items-center mb-3"
      >
        <span class="ft__03">Lokalizacje</span>
        <!-- <div *ngIf="sumy" style="display: flex; flex-direction: row; gap: 20px">
          <span>Spisane środki: {{ sumy.spisaneSrodki }}</span>
          <span>Spisane IPRO: {{ sumy.ipro }}</span>
          <span>Oświadczenia: {{ sumy.oswiadczenia }}</span>
          <span>Sparowane: {{ sumy.sparowane }}</span>
        </div> -->
        <div class="input_container">
          <button
            class="btn__clear--filter"
            (click)="wyczyscFiltry()"
            *ngIf="czyPokazacWyczyscFiltry()"
          >
            Wyczyść filtry
          </button>
        </div>
      </div>
      <div class="tableFixHead tableFixHead--3">
        <table class="table">
          <thead>
            <tr>
              <th>
                Lp.
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('lp')"
                  (click)="zmienKierunekSortowania('lp')"
                ></button>
                <div
                  class="input_container input_container--mini"
                  style="width: 135px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.lp"
                    style="width: 135px"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.lp)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.lp"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.lp"
                    (click)="
                      this.wyszukiwanie.filtry.lp = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Ulica
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('ulica')"
                  (click)="zmienKierunekSortowania('ulica')"
                ></button>

                <div
                  class="input_container input_container--mini"
                  style="width: 135px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.ulica"
                    style="width: 135px"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.ulica)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.ulica"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.ulica"
                    (click)="
                      this.wyszukiwanie.filtry.ulica = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Miasto
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('miasto')"
                  (click)="zmienKierunekSortowania('miasto')"
                ></button>

                <div
                  class="input_container input_container--mini"
                  style="width: 135px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.miasto"
                    style="width: 135px"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.miasto)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.miasto"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.miasto"
                    (click)="
                      this.wyszukiwanie.filtry.miasto = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Kod pocztowy
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('kodPocztowy')"
                  (click)="zmienKierunekSortowania('kodPocztowy')"
                ></button>

                <div
                  class="input_container input_container--mini"
                  style="width: 135px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.kodPocztowy"
                    style="width: 135px"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.kodPocztowy)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.kodPocztowy"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.kodPocztowy"
                    (click)="
                      this.wyszukiwanie.filtry.kodPocztowy = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                Rodzaj lokalizacji
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('rodzajLokalizacji')"
                  (click)="zmienKierunekSortowania('rodzajLokalizacji')"
                ></button>

                <div
                  class="input_container input_container--mini"
                  style="width: 155px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.rodzajLokalizacji"
                    style="width: 155px"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.rodzajLokalizacji
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.rodzajLokalizacji"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.rodzajLokalizacji"
                    (click)="
                      this.wyszukiwanie.filtry.rodzajLokalizacji = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                MPK
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('mpk')"
                  (click)="zmienKierunekSortowania('mpk')"
                ></button>

                <div
                  class="input_container input_container--mini"
                  style="width: 135px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.mpk"
                    style="width: 135px"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.mpk)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.mpk"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.mpk"
                    (click)="
                      this.wyszukiwanie.filtry.mpk = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: end;
                  "
                >
                  <div style="display: flex; flex-direction: row; gap: 5px">
                    Spisane środki
                    <span *ngIf="sumy">
                      <strong> ({{ sumy.spisaneSrodki }})</strong>
                    </span>
                  </div>

                  <button
                    class="btn__input--sort--bottom--gray"
                    [ngClass]="
                      pobierzKierunekSortowania('sumaSpisanychSrodkow')
                    "
                    style="margin-bottom: 5px"
                    (click)="zmienKierunekSortowania('sumaSpisanychSrodkow')"
                  ></button>
                </div>

                <div
                  class="input_container input_container--mini"
                  style="width: 205px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.sumaSpisanychSrodkow"
                    style="width: 205px"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.sumaSpisanychSrodkow
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.sumaSpisanychSrodkow"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.sumaSpisanychSrodkow"
                    (click)="
                      this.wyszukiwanie.filtry.sumaSpisanychSrodkow = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: end;
                  "
                >
                  <div style="display: flex; flex-direction: row; gap: 5px">
                    Spisane IPRO
                    <span *ngIf="sumy">
                      <strong>({{ sumy.ipro }})</strong>
                    </span>
                  </div>

                  <button
                    class="btn__input--sort--bottom--gray"
                    [ngClass]="pobierzKierunekSortowania('spisaneSrodkiIpro')"
                    style="margin-bottom: 5px"
                    (click)="zmienKierunekSortowania('spisaneSrodkiIpro')"
                  ></button>
                </div>

                <div
                  class="input_container input_container--mini"
                  style="width: 205px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.spisaneSrodkiIpro"
                    style="width: 205px"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.spisaneSrodkiIpro
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.spisaneSrodkiIpro"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.spisaneSrodkiIpro"
                    (click)="
                      this.wyszukiwanie.filtry.spisaneSrodkiIpro = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: end;
                  "
                >
                  <div style="display: flex; flex-direction: row; gap: 5px">
                    Oświadczenia
                    <span *ngIf="sumy">
                      <strong>({{ sumy.oswiadczenia }})</strong>
                    </span>
                  </div>

                  <button
                    class="btn__input--sort--bottom--gray"
                    [ngClass]="pobierzKierunekSortowania('srodkiZOswiadczen')"
                    style="margin-bottom: 5px"
                    (click)="zmienKierunekSortowania('srodkiZOswiadczen')"
                  ></button>
                </div>

                <div
                  class="input_container input_container--mini"
                  style="width: 185px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.srodkiZOswiadczen"
                    style="width: 185px"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.srodkiZOswiadczen
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.srodkiZOswiadczen"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.srodkiZOswiadczen"
                    (click)="
                      this.wyszukiwanie.filtry.srodkiZOswiadczen = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: end;
                  "
                >
                  <div style="display: flex; flex-direction: row; gap: 5px">
                    Sparowane
                    <span *ngIf="sumy">
                      <strong>({{ sumy.sparowane }})</strong>
                    </span>
                  </div>

                  <button
                    class="btn__input--sort--bottom--gray"
                    [ngClass]="pobierzKierunekSortowania('sparowaneSrodki')"
                    style="margin-bottom: 5px"
                    (click)="zmienKierunekSortowania('sparowaneSrodki')"
                  ></button>
                </div>

                <div
                  class="input_container input_container--mini"
                  style="width: 185px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.sparowaneSrodki"
                    style="width: 185px"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.sparowaneSrodki)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.sparowaneSrodki"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.sparowaneSrodki"
                    (click)="
                      this.wyszukiwanie.filtry.sparowaneSrodki = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Środki z ewidencji
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('srodkiZEwidencji')"
                  (click)="zmienKierunekSortowania('srodkiZEwidencji')"
                ></button>

                <div
                  class="input_container input_container--mini"
                  style="width: 135px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.srodkiZEwidencji"
                    style="width: 135px"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.srodkiZEwidencji
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.srodkiZEwidencji"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.srodkiZEwidencji"
                    (click)="
                      this.wyszukiwanie.filtry.srodkiZEwidencji = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Numer zespołu
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('numerZespolu')"
                  (click)="zmienKierunekSortowania('numerZespolu')"
                ></button>

                <div
                  class="input_container input_container--mini"
                  style="width: 135px"
                >
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.numerZespolu"
                    style="width: 135px"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.numerZespolu)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.numerZespolu"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.numerZespolu"
                    (click)="
                      this.wyszukiwanie.filtry.numerZespolu = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(lokalizacje)">
            <tr
              *ngFor="let lokalizacja of lokalizacje"
              (click)="wybierzLokalizacje(lokalizacja.id)"
              class="pointer project-row"
            >
              <td>{{ lokalizacja.lp }}</td>
              <td>{{ lokalizacja.ulica }}</td>
              <td>{{ lokalizacja.miasto }}</td>
              <td>{{ lokalizacja.kodPocztowy }}</td>
              <td>{{ lokalizacja.rodzajLokalizacji }}</td>
              <td>{{ lokalizacja.mpk }}</td>
              <td>{{ lokalizacja.sumaSpisanychSrodkow }}</td>
              <td>{{ lokalizacja.spisaneSrodkiIpro }}</td>
              <td>{{ lokalizacja.srodkiZOswiadczen }}</td>
              <td>{{ lokalizacja.sparowaneSrodki }}</td>
              <td>{{ lokalizacja.srodkiZEwidencji }}</td>
              <td>{{ lokalizacja.numerZespolu }}</td>
              <td><button class="btn__arrow"></button></td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(lokalizacje)"
        >
          <div>Brak lokalizacji dla wybranych filtrów</div>
        </div>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pt-4 pb-2 pt-lg-2 mt-1"
      *ngIf="czySaWynikiNaLiscie(lokalizacje)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
  </div>
  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">{{ nazwaProjektu }}</div>
    </div>

    <div class="d-fledx py-3" *ngIf="!czyPracownikKlienta()">
      <div class="project__menu">
        <button class="btn__left"></button>
        <ngx-slick-carousel
          class="carousel menu_slider"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
          <div ngxSlickItem class="slide">
            <a routerLink="/project-details/{{ idProjektu }}">Projekt</a>
          </div>
          <div ngxSlickItem class="slide">
            <a class="active" routerLink="/inventory/{{ idProjektu }}">Spis</a>
          </div>
          <div ngxSlickItem class="slide">
            <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
          </div>
          <div ngxSlickItem class="slide">
            <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/project-dictionaries/{{ idProjektu }}">Słowniki</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/teams/{{ idProjektu }}">Zespoły</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/inventory-fields/{{ idProjektu }}">Pola spisu</a>
          </div>
        </ngx-slick-carousel>
        <button class="btn__right"></button>
      </div>
    </div>

    <div class="filter-box d-flex justify-content-between pt-3 mb-3">
      <span class="ft__03">Lokalizacje</span>
      <div class="dropdown person_desktop text-right">
        <a
          class="btn__input--sort dropdown-toggle"
          [routerLink]=""
          (click)="pokazMenuSortowania()"
          role="button"
          id="dropdownSort"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (clickOutside)="czyPokazacMenuSortowanie = false"
        >
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownSort"
          *ngIf="czyPokazacMenuSortowanie"
          style="display: block; position: absolute"
        >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('lp')"
            >Lp</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('ulica')"
            >Ulica
          </a>
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('miasto')"
            >Miasto</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('kodPocztowy')"
            >Kod pocztowy</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('rodzajLokalizacji')"
            >Rodzaj lokalizacji</a
          ><a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('mpk')"
            >MPK</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('sumaSpisanychSrodkow')"
            >Spisane środki</a
          >

          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('spisaneSrodkiIpro')"
            >Spisane IPRO</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('srodkiZOswiadczen')"
            >Oświadczenia</a
          >

          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('sparowaneSrodki')"
            >Sparowane</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('srodkiZEwidencji')"
            >Środki z ewidencji</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('numerZespolu')"
            >Numer zespołu</a
          >
        </div>
      </div>
    </div>

    <div *ngIf="sumy" style="display: flex; flex-direction: column">
      <span>Spisane środki: {{ sumy.spisaneSrodki }}</span>
      <span>Spisane IPRO: {{ sumy.ipro }}</span>
      <span>Oświadczenia: {{ sumy.oswiadczenia }}</span>
      <span>Sparowane: {{ sumy.sparowane }}</span>
    </div>
    <div
      class="filter-select pt-3 pb-2 d-flex flex-column mb-3"
      *ngIf="pobierzKolumneSortowania()"
    >
      <div class="d-flex pb-2">
        <div class="filter_item">
          <button class="btn__x" (click)="wyczyscSortowanieMobile()"></button>
          <span class="fs__sort"
            >Sortowanie: {{ pobierzKolumneSortowania() }}</span
          >
        </div>
        <button
          class="btn__input--sort--bottom ml-3"
          [ngClass]="pobierzKierunekSortowaniaMobile()"
          (click)="zmienKierunekSortowaniaMobile()"
        ></button>
      </div>
    </div>

    <div
      class="input_container mb-3 text-right"
      *ngIf="!czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__search"
        (click)="pokazWyszukiwarkeMobile(true)"
      ></button>
    </div>
    <div
      class="searchBox d-flex flex-wrap por pb-5"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__input--clear"
        (click)="pokazWyszukiwarkeMobile(false)"
      ></button>
    </div>

    <div
      class="box__mobile box__mobile--form mb-3"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="lp"
          [(ngModel)]="wyszukiwanie.filtry.lp"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.lp"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.lp"
          (click)="this.wyszukiwanie.filtry.lp = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Ulica"
          [(ngModel)]="wyszukiwanie.filtry.ulica"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.ulica"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.ulica"
          (click)="this.wyszukiwanie.filtry.ulica = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Miasto"
          [(ngModel)]="wyszukiwanie.filtry.miasto"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.miasto"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.miasto"
          (click)="this.wyszukiwanie.filtry.miasto = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Kod pocztowy"
          [(ngModel)]="wyszukiwanie.filtry.kodPocztowy"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.kodPocztowy"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.kodPocztowy"
          (click)="this.wyszukiwanie.filtry.kodPocztowy = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Rodzaj lokalizacji"
          [(ngModel)]="wyszukiwanie.filtry.rodzajLokalizacji"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.rodzajLokalizacji"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.rodzajLokalizacji"
          (click)="this.wyszukiwanie.filtry.rodzajLokalizacji = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="MPK"
          [(ngModel)]="wyszukiwanie.filtry.mpk"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.mpk"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.mpk"
          (click)="this.wyszukiwanie.filtry.mpk = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Spisane środki"
          [(ngModel)]="wyszukiwanie.filtry.sumaSpisanychSrodkow"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.sumaSpisanychSrodkow"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.sumaSpisanychSrodkow"
          (click)="this.wyszukiwanie.filtry.sumaSpisanychSrodkow = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Spisane IPRO"
          [(ngModel)]="wyszukiwanie.filtry.spisaneSrodkiIpro"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.spisaneSrodkiIpro"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.spisaneSrodkiIpro"
          (click)="this.wyszukiwanie.filtry.spisaneSrodkiIpro = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Oświadczenia"
          [(ngModel)]="wyszukiwanie.filtry.srodkiZOswiadczen"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.srodkiZOswiadczen"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.srodkiZOswiadczen"
          (click)="this.wyszukiwanie.filtry.srodkiZOswiadczen = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Sparowane"
          [(ngModel)]="wyszukiwanie.filtry.sparowaneSrodki"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.sparowaneSrodki"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.sparowaneSrodki"
          (click)="this.wyszukiwanie.filtry.sparowaneSrodki = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Środki z ewidencji"
          [(ngModel)]="wyszukiwanie.filtry.srodkiZEwidencji"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.srodkiZEwidencji"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.srodkiZEwidencji"
          (click)="this.wyszukiwanie.filtry.srodkiZEwidencji = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Numer zespołu"
          [(ngModel)]="wyszukiwanie.filtry.numerZespolu"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.numerZespolu"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.numerZespolu"
          (click)="this.wyszukiwanie.filtry.numerZespolu = ''"
        ></button>
      </div>
    </div>

    <button
      class="btn__border mt-4 mb-4"
      (click)="pobierzDaneDoEkranu(true); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      Szukaj
    </button>

    <button
      class="btn__clear--filter mt-2 mb-4"
      (click)="wyczyscFiltry(); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazacWyczyscFiltry()"
    >
      Wyczyść filtry
    </button>

    <div
      class="no-results text-center ft__no-results"
      *ngIf="!czySaWynikiNaLiscie(lokalizacje)"
    >
      <div>Brak lokalizacji<br />dla wybranych filtrów</div>
    </div>

    <div
      class="box__mobile mb-3"
      *ngFor="let lokalizacja of lokalizacje"
      (click)="wybierzLokalizacje(lokalizacja.id)"
    >
      <div
        class="d-flex justify-content-between align-items-center pb-2 mb-2 pt-2"
      >
        <span class="fs__pname">Lp. {{ lokalizacja.lp }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Ulica</span>
        <span class="fs__pname--3 text-lg2">{{ lokalizacja.ulica }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Miasto</span>
        <span class="fs__pname--3 text-lg2">{{ lokalizacja.miasto }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Kod pocztowy</span>
        <span class="fs__pname--3 text-lg2">{{ lokalizacja.kodPocztowy }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Rodzaj lokalizacji</span>
        <span class="fs__pname--3 text-lg2">{{
          lokalizacja.rodzajLokalizacji
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">MPK</span>
        <span class="fs__pname--3 text-lg2">{{ lokalizacja.mpk }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Spisane środki</span>
        <span class="fs__pname--3 text-lg2">{{
          lokalizacja.sumaSpisanychSrodkow
        }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Spisane IPRO</span>
        <span class="fs__pname--3 text-lg2">{{
          lokalizacja.spisaneSrodkiIpro
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Oświadczenia</span>
        <span class="fs__pname--3 text-lg2">{{
          lokalizacja.srodkiZOswiadczen
        }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Sparowane</span>
        <span class="fs__pname--3 text-lg2">{{
          lokalizacja.sparowaneSrodki
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Środki z ewidencji</span>
        <span class="fs__pname--3 text-lg2">{{
          lokalizacja.srodkiZEwidencji
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Numer zespołu</span>
        <span class="fs__pname--3 text-lg2">{{
          lokalizacja.numerZespolu
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center mr-3 m_border"
      >
        <span class="fs__pname--2">&nbsp;</span>
        <span class="fs__pname--3 text-lg2"
          ><button class="btn__arrow"></button
        ></span>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
      *ngIf="czySaWynikiNaLiscie(lokalizacje)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
    <button
      class="btn__border btn__border--mini px-3 mt-3"
      (click)="eksportujDane()"
    >
      Eksportuj dane
    </button>

    <button class="btn__primary mt-3" (click)="przejdzDoOswiadczen()">
      Oświadczenia
    </button>

    <button
      class="btn__link order-1 order-sm-0"
      (click)="cofnij()"
      *ngIf="!czyPracownikKlienta()"
    >
      Cofnij
    </button>
  </div>

  <div class="bootom__buttons px-4 d-none d-md-block">
    <div
      class="container--mw600 flex-column flex-sm-row d-flex justify-content-between align-items-center"
    >
      <button
        class="btn__link order-1 order-sm-0"
        (click)="cofnij()"
        *ngIf="!czyPracownikKlienta()"
      >
        Cofnij
      </button>
      <button class="btn__blue" (click)="przejdzDoOswiadczen()">
        Oświadczenia
      </button>
      <button
        class="btn__border btn__border--mini px-3"
        (click)="eksportujDane()"
      >
        Eksportuj dane
      </button>
    </div>
  </div>
</div>
