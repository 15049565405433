export class PobierzLokalizacjeDoSpisuFiltr {
  lp: string;
  ulica: string;
  miasto: string;
  kodPocztowy: string;
  rodzajLokalizacji: string;
  projektId: string;
  numerZespolu: string;
  sumaSpisanychSrodkow: string;
  sparowaneSrodki: string;
  srodkiZEwidencji: string;
  mpk: string;
  spisaneSrodkiIpro: string;
  srodkiZOswiadczen: string;
}
