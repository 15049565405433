import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { StatusProjektu } from 'src/app/modele/projekty/statusProjektu';
import { SzczegolyProjektu } from 'src/app/modele/projekty/szczegolyProjektu';
import { IdModel } from 'src/app/modele/wspolne/id.model';
import { ModalOpcje } from 'src/app/modele/wspolne/modal-opcje.ogolne';
import { Routing } from 'src/app/modele/wspolne/routing';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { EksportDanychService } from 'src/app/serwisy/projekty/eksport-danych.service';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';
import { BazowyComponent } from '../../bazowy.component';
import { TakNieModalComponent } from '../../ogolne/tak-nie-modal/tak-nie-modal.component';
import { OswiadczeniaService } from 'src/app/serwisy/oswiadczenia/oswiadczenia.service';
import { KopiujProjektModalComponent } from '../kopiuj-projekt-modal/kopiuj-projekt-modal.component';

@Component({
  selector: 'app-szczegoly-projektu',
  templateUrl: './szczegoly-projektu.component.html',
  styleUrls: ['./szczegoly-projektu.component.css'],
  providers: [DatePipe],
})
export class SzczegolyProjektuComponent
  extends BazowyComponent
  implements OnInit
{
  idProjektu: string;
  akcja: string;
  nazwaPlikuEksportu: string;

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: document.getElementsByClassName('btn__left'),
    nextArrow: document.getElementsByClassName('btn__right'),
    infinite: false,
    variableWidth: true,
  };
  czyZaladowanoDane: boolean;
  szczegoly: SzczegolyProjektu;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    private datePipe: DatePipe,
    public projektySerwis: ProjektyService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute,
    public eskportDanychSerwis: EksportDanychService,
    private oswiadczeniaSerwis: OswiadczeniaService
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idProjektu = this.activatedRoute.snapshot.paramMap.get('id');
      this.akcja = this.activatedRoute.snapshot.paramMap.get('akcja');
      this.pobierzDaneDoEkranu();
    });
  }

  pobierzDaneDoEkranu() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.projektySerwis.pobierzSzczegoly(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.szczegoly = rezultat.element;
        this.nazwaPlikuEksportu = rezultat.element.nazwaPlikuEksportu;

        this.czyZaladowanoDane = true;
        if (this.akcja == 'download') {
          this.pobierzZrzuconeDane();
        }

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }
  wyszukiwanie(wyszukiwanie: any, zLadowaczem: boolean) {
    throw new Error('Method not implemented.');
  }
  przejdzDoSzczegolow(id: any) {
    throw new Error('Method not implemented.');
  }

  przejdzDoEdycji() {
    this.router.navigate([Routing.EdytujProjekt, this.idProjektu]);
  }

  cofnij() {
    this.router.navigate([Routing.Projekty]);
  }
  poprzedniSlajd() {}
  nastepnySlajd() {}

  ustalStatus() {
    switch (this.szczegoly.status) {
      case StatusProjektu.przygotowanie:
        return this.pobierzTlumaczenie('projekty.status.przygotowanie');
      case StatusProjektu.realizacja:
        return this.pobierzTlumaczenie('projekty.status.realizacja');
      case StatusProjektu.zakonczony:
        return this.pobierzTlumaczenie('projekty.status.zakonczony');
      case StatusProjektu.zarchiwizowany:
        return this.pobierzTlumaczenie('projekty.status.zarchiwizowany');
    }
  }

  formatujDate(data?: Date) {
    if (data == undefined) {
      return '';
    }
    return this.datePipe.transform(data, 'dd.MM.yyyy');
  }

  zrzucDane() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.eskportDanychSerwis
      .przygotujDaneDoZrzucenia(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          if (rezultat.element.czyOperacjaWTle) {
            this.wyswietlInformacje(
              'Dane przygotowywane są do eksportu. Informację o zakończeniu eksportu otrzymasz na adres email'
            );
          } else {
            this.szczegoly.czyJestPlikEksportuDoPobrania = true;
            this.nazwaPlikuEksportu = rezultat.element.nazwaPliku;
            this.pobierzZrzuconeDane();
          }

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  pobierzZrzuconeDane() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.eskportDanychSerwis
      .pobierzWyeksportowaneDaneProjektu(model)
      .subscribe((rezultat) => {
        this.rozpocznijPobieranieEksportu(rezultat);
      });
  }

  rozpocznijPobieranieEksportu(dane) {
    if (dane.type != 'application/octet-stream') {
      return;
    }
    const contentType = 'application/zip';
    const blob = new Blob([dane], { type: contentType });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = this.nazwaPlikuEksportu + '.zip';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  czyPokazacPrzyciskEksportuDanych() {
    return this.czyAdmin();
  }
  czyPokazacPrzyciskPobraniaEksportuDanych() {
    return this.czyAdmin() && this.szczegoly.czyJestPlikEksportuDoPobrania;
  }

  czyPokazacPrzyciskUsunProjekt() {
    return (
      this.czyAdmin() && this.szczegoly.status == StatusProjektu.zarchiwizowany
    );
  }

  usunProjekt() {
    this.modalReference = this.modalService.open(
      TakNieModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.naglowek =
      ' Czy potwierdzasz usunięcie danych projektu? ';
    this.modalReference.componentInstance.pytanie =
      'Po potwierdzeniu wszystkie dane zostaną usunięte bezpowrotnie.';

    this.modalReference.result.then(
      (result) => {
        if (result) {
          var model = new IdModel();
          model.id = this.idProjektu;
          this.projektySerwis.usunProjekt(model).subscribe((rezultat) => {
            if (this.czyRezultatOk(rezultat)) {
              this.wyswietlKomunikat('Projekt został usunięty');
              this.router.navigate([Routing.Projekty]);
              return;
            }

            this.wyswietlKomunikat(rezultat.wiadomosc, true);
            return;
          });
        }
      },
      (onRejected) => {}
    );
  }

  pobierzAdresOswiadczen() {
    var adresStrony = location.origin;
    var url = adresStrony + '/' + Routing.Oswiadczenia + '/' + this.idProjektu;

    navigator.clipboard.writeText(url).then(() => {
      this.wyswietlKomunikat('Adres został skopiowany do schowka');
    });
  }

  pobierzInstrukcje() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.projektySerwis.pobierzInstrukcje(model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = 'data: pdf;base64,' + rezultat.element.plik;
        a.download = rezultat.element.nazwaPliku;
        a.click();

        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  przejdzDoOswiadczenJakoAdmin() {
    var model = new IdModel();
    model.id = this.idProjektu;
    this.projektySerwis
      .przejdzDoOswiadczenJakoAdmin(model)
      .subscribe((rezultat) => {
        if (this.czyRezultatOk(rezultat)) {
          rezultat.element.potwierdzonoZapoznanieZInstrukcja = true;
          localStorage.setItem('uzytkownik', JSON.stringify(rezultat.element));
          var adresStrony = location.origin;
          var url =
            adresStrony + '/' + Routing.Oswiadczenia + '/' + this.idProjektu;
          window.open(url, '_blank');

          return;
        }

        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  kopiujProjekt() {
    this.modalReference = this.modalService.open(
      KopiujProjektModalComponent,
      new ModalOpcje(true, 'static')
    );
    this.modalReference.componentInstance.idProjektu = this.idProjektu;

    this.modalReference.result.then(
      (result: { rezultat: boolean; id: string }) => {
        if (result.rezultat) {
          this.router.navigate([Routing.SzczegolyProjektu, result.id]);
          this.idProjektu = result.id;
          this.pobierzDaneDoEkranu();
        }
      },
      (onRejected) => {}
    );
  }
}
