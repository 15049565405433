import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Zalacznik } from 'src/app/modele/wspolne/zalacznik';
import { Konfiguracja } from 'src/config';

@Component({
  selector: 'app-informacje-o-polu-modal',
  templateUrl: './informacje-o-polu-modal.component.html',
  styleUrls: ['./informacje-o-polu-modal.component.css'],
})
export class InformacjeOPoluModalComponent implements OnInit {
  informacjeOPoluSpisu: string;
  zdjecie?: Zalacznik;
  @ViewChild('zdjecieFileInput') zdjecieFileInput: ElementRef;
  pokazGalerieZdjec: boolean = false;

  fullScreenZdjecia: Array<object> = new Array();

  constructor(public router: Router, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  pokazGalerie() {
    this.fullScreenZdjecia = [{ image: this.sciezkaPliku() }];
    this.pokazGalerieZdjec = true;
  }
  zamknijGalerie() {
    this.pokazGalerieZdjec = false;
  }
  sciezkaPliku() {
    return new Konfiguracja().AdresApi + this.zdjecie.sciezkaDoPliku;
  }
}
