<div *ngIf="czyZaladowanoDane">
  <div class="container--main d-none d-md-block">
    <div class="ft__sort text-center pt-4">{{ nazwaProjektu }}</div>

    <div class="d-flex pt-3">
      <div class="project__menu" *ngIf="!czyPracownikKlienta()">
        <a routerLink="/project-details/{{ idProjektu }}">Projekt</a>
        <a class="active" routerLink="/inventory/{{ idProjektu }}">Spis</a>
        <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
        <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
        <a
          routerLink="/project-dictionaries/{{ idProjektu }}"
          *ngIf="czyAdmin()"
          >Słowniki</a
        >
        <a routerLink="/teams/{{ idProjektu }}" *ngIf="czyAdmin()">Zespoły</a>
        <a routerLink="/inventory-fields/{{ idProjektu }}" *ngIf="czyAdmin()"
          >Pola spisu</a
        >
      </div>
    </div>
  </div>

  <div class="container-fluid d-none d-md-block pt-4">
    <div class="box__scroll py-3">
      <div
        class="box__scroll--top d-flex justify-content-between align-items-center mb-3"
      >
        <span class="ft__04 pt-1 mt-1">{{ adresLokalizacji }}</span>

        <div class="mt-2">
          <button class="btn__badge active" (click)="pokazEkranPomieszczen()">
            Pomieszczenia
          </button>
          <button class="btn__badge" (click)="pokazEkranSpisanychSrodkow()">
            Lista spisanych środków
          </button>
          <button class="btn__badge" (click)="pokazEkranNadwyzek()">
            Nadwyżki
          </button>
          <button class="btn__badge" (click)="pokazEkranNiedoborow()">
            Niedobory
          </button>
          <button class="btn__badge" (click)="pokazEkranSrodkowZOswiadczen()">
            Oświadczenia
          </button>
        </div>

        <div class="input_container">
          <button
            class="btn__clear--filter mr-3"
            (click)="wyczyscFiltry()"
            *ngIf="czyPokazacWyczyscFiltry()"
          >
            Wyczyść filtry
          </button>
        </div>
      </div>
      <div class="tableFixHead tableFixHead--3">
        <table class="table">
          <thead>
            <tr>
              <th *ngIf="czyPrzenoszeniePomieszczen"></th>
              <th>
                Nazwa
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('nazwa')"
                  (click)="zmienKierunekSortowania('nazwa')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.nazwa"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.nazwa)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.nazwa"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.nazwa"
                    (click)="
                      this.wyszukiwanie.filtry.nazwa = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Piętro
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('pietro')"
                  (click)="zmienKierunekSortowania('pietro')"
                ></button>

                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.pietro"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.pietro)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.pietro"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.pietro"
                    (click)="
                      this.wyszukiwanie.filtry.pietro = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                MPK
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('mpk')"
                  (click)="zmienKierunekSortowania('mpk')"
                ></button>

                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.mpk"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.mpk)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.mpk"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.mpk"
                    (click)="
                      this.wyszukiwanie.filtry.mpk = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Opis
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('opis')"
                  (click)="zmienKierunekSortowania('opis')"
                ></button>

                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.opis"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.opis)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.opis"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.opis"
                    (click)="
                      this.wyszukiwanie.filtry.opis = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Osoba
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('osoba')"
                  (click)="zmienKierunekSortowania('osoba')"
                ></button>

                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.osoba"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.osoba)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.osoba"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.osoba"
                    (click)="
                      this.wyszukiwanie.filtry.osoba = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Inne
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('inne')"
                  (click)="zmienKierunekSortowania('inne')"
                ></button>

                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.inne"
                    (keyup)="filtrujPoTekscie(this.wyszukiwanie.filtry.inne)"
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.inne"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.inne"
                    (click)="
                      this.wyszukiwanie.filtry.inne = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>

              <th>
                Spisane środki
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('sumaSpisanychSrodkow')"
                  (click)="zmienKierunekSortowania('sumaSpisanychSrodkow')"
                ></button>

                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.sumaSpisanychSrodkow"
                    (keyup)="
                      filtrujPoTekscie(
                        this.wyszukiwanie.filtry.sumaSpisanychSrodkow
                      )
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.sumaSpisanychSrodkow"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.sumaSpisanychSrodkow"
                    (click)="
                      this.wyszukiwanie.filtry.sumaSpisanychSrodkow = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Sparowane
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('sparowaneSrodki')"
                  (click)="zmienKierunekSortowania('sparowaneSrodki')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.sparowaneSrodki"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.sparowaneSrodki)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.sparowaneSrodki"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.sparowaneSrodki"
                    (click)="
                      this.wyszukiwanie.filtry.sparowaneSrodki = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th>
                Utworzył
                <button
                  class="btn__input--sort--bottom--gray"
                  [ngClass]="pobierzKierunekSortowania('utworzyl')"
                  (click)="zmienKierunekSortowania('utworzyl')"
                ></button>
                <div class="input_container input_container--mini">
                  <input
                    class="pr-4"
                    type="text"
                    placeholder="Szukaj"
                    [(ngModel)]="wyszukiwanie.filtry.utworzyl"
                    (keyup)="
                      filtrujPoTekscie(this.wyszukiwanie.filtry.utworzyl)
                    "
                  />
                  <button
                    class="btn__input--search"
                    *ngIf="!wyszukiwanie.filtry.utworzyl"
                  ></button>
                  <button
                    class="btn__input--clear"
                    *ngIf="wyszukiwanie.filtry.utworzyl"
                    (click)="
                      this.wyszukiwanie.filtry.utworzyl = '';
                      pobierzDaneDoEkranu(false)
                    "
                  ></button>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody class="fz12" *ngIf="czySaWynikiNaLiscie(pomieszczenia)">
            <tr
              *ngFor="let pomieszczenie of pomieszczenia"
              class="pointer project-row"
              (click)="wybierzPomieszczenie(pomieszczenie.id)"
            >
              <td
                *ngIf="
                  czyPrzenoszeniePomieszczen &&
                  !pomieszczenie.czyPomieszczenieOswiadczen
                "
                (click)="$event.stopPropagation()"
              >
                <div class="chb por">
                  <input
                    id="cb_{{ pomieszczenie.id }}"
                    type="checkbox"
                    name="{{ pomieszczenie.id }}"
                    (change)="pomieszczenieZmiana($event)"
                    [(ngModel)]="pomieszczenie.czyWybrano"
                  />
                  <label
                    class="fs__chb mt-2 pr-0 pl-0"
                    for="cb_{{ pomieszczenie.id }}"
                    >&nbsp;</label
                  >
                </div>
              </td>
              <td
                *ngIf="
                  czyPrzenoszeniePomieszczen &&
                  pomieszczenie.czyPomieszczenieOswiadczen
                "
              ></td>
              <td>{{ pomieszczenie.nazwa }}</td>
              <td>{{ pomieszczenie.pietro }}</td>
              <td>{{ pomieszczenie.mpk }}</td>
              <td>{{ pomieszczenie.opis }}</td>
              <td>{{ pomieszczenie.osoba }}</td>
              <td>{{ pomieszczenie.inne }}</td>
              <td>{{ pomieszczenie.sumaSpisanychSrodkow }}</td>
              <td>{{ pomieszczenie.sparowaneSrodki }}</td>
              <td>{{ pomieszczenie.utworzyl }}</td>
              <td>
                <button
                  class="btn__edit"
                  *ngIf="
                    !czyProjektZarchiwizowany() &&
                    !pomieszczenie.czyPomieszczenieOswiadczen &&
                    !czyPracownikKlienta()
                  "
                  (click)="
                    $event.stopPropagation();
                    edytujPomieszczenie(pomieszczenie.id)
                  "
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="ft__03 text-gray text-center my-5 py-5"
          *ngIf="!czySaWynikiNaLiscie(pomieszczenia)"
        >
          <div>Brak pomieszczeń dla wybranych filtrów</div>
        </div>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pt-4 pb-2 pt-lg-2 mt-1"
      *ngIf="czySaWynikiNaLiscie(pomieszczenia)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
  </div>
  <!-- mobile -->
  <div class="container-fluid d-md-none pb-5">
    <div class="pt-3 mt-1">
      <div class="ft__sort text-center">{{ nazwaProjektu }}</div>
    </div>

    <div class="d-fledx py-3" *ngIf="!czyPracownikKlienta()">
      <div class="project__menu">
        <button class="btn__left"></button>
        <ngx-slick-carousel
          class="carousel menu_slider"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
          <div ngxSlickItem class="slide">
            <a routerLink="/project-details/{{ idProjektu }}">Projekt</a>
          </div>
          <div ngxSlickItem class="slide">
            <a class="active" routerLink="/inventory/{{ idProjektu }}">Spis</a>
          </div>
          <div ngxSlickItem class="slide">
            <a routerLink="/locations/{{ idProjektu }}">Lokalizacje</a>
          </div>
          <div ngxSlickItem class="slide">
            <a routerLink="/client-data/{{ idProjektu }}">Ewidencja klienta</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/project-dictionaries/{{ idProjektu }}">Słowniki</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/teams/{{ idProjektu }}">Zespoły</a>
          </div>
          <div ngxSlickItem class="slide" *ngIf="czyAdmin()">
            <a routerLink="/inventory-fields/{{ idProjektu }}">Pola spisu</a>
          </div>
        </ngx-slick-carousel>
        <button class="btn__right"></button>
      </div>
    </div>

    <div class="filter-box d-flex justify-content-between pt-3 mb-3">
      <span class="ft__03 mr-3">{{ adresLokalizacji }}</span>
      <div class="dropdown person_desktop text-right">
        <a
          class="btn__input--sort dropdown-toggle"
          [routerLink]=""
          (click)="pokazMenuSortowania()"
          role="button"
          id="dropdownSort"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          (clickOutside)="czyPokazacMenuSortowanie = false"
        >
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownSort"
          *ngIf="czyPokazacMenuSortowanie"
          style="display: block; position: absolute"
        >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('nazwa')"
            >Nazwa</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('pietro')"
            >Pietro</a
          >

          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('mpk')"
            >MPK</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('opis')"
            >Opis</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('osoba')"
            >Osoba</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('inne')"
            >Inne</a
          >

          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('sumaSpisanychSrodkow')"
            >Spisane środki</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('sparowaneSrodki')"
            >Sparowane</a
          >
          <a
            class="dropdown-item"
            [routerLink]=""
            (click)="zmienKierunekSortowania('utworzyl')"
            >Utworzył</a
          >
        </div>
      </div>
    </div>

    <div
      class="filter-select pt-3 pb-2 d-flex flex-column mb-3"
      *ngIf="pobierzKolumneSortowania()"
    >
      <div class="d-flex pb-2">
        <div class="filter_item">
          <button class="btn__x" (click)="wyczyscSortowanieMobile()"></button>
          <span class="fs__sort"
            >Sortowanie: {{ pobierzKolumneSortowania() }}</span
          >
        </div>
        <button
          class="btn__input--sort--bottom ml-3"
          [ngClass]="pobierzKierunekSortowaniaMobile()"
          (click)="zmienKierunekSortowaniaMobile()"
        ></button>
      </div>
    </div>

    <div
      class="input_container mb-4 text-right"
      *ngIf="!czyPokazanoWyszukiwarkeMobile"
    >
      <button class="btn__hamburger" (click)="pokazPodmenu()"></button>
      <button
        class="btn__search mx-2"
        (click)="pokazWyszukiwarkeMobile(true)"
      ></button>
    </div>
    <div
      class="searchBox d-flex flex-wrap por pb-5"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <button
        class="btn__input--clear"
        (click)="pokazWyszukiwarkeMobile(false)"
      ></button>
    </div>

    <div
      class="box__mobile box__mobile--form mb-3"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Nazwa"
          [(ngModel)]="wyszukiwanie.filtry.nazwa"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.nazwa"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.nazwa"
          (click)="this.wyszukiwanie.filtry.nazwa = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Piętro"
          [(ngModel)]="wyszukiwanie.filtry.pietro"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.pietro"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.pietro"
          (click)="this.wyszukiwanie.filtry.pietro = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="MPK"
          [(ngModel)]="wyszukiwanie.filtry.mpk"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.mpk"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.mpk"
          (click)="this.wyszukiwanie.filtry.mpk = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Opis"
          [(ngModel)]="wyszukiwanie.filtry.opis"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.opis"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.opis"
          (click)="this.wyszukiwanie.filtry.opis = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Osoba"
          [(ngModel)]="wyszukiwanie.filtry.osoba"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.osoba"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.osoba"
          (click)="this.wyszukiwanie.filtry.osoba = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Inne"
          [(ngModel)]="wyszukiwanie.filtry.inne"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.inne"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.inne"
          (click)="this.wyszukiwanie.filtry.inne = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Spisane środki"
          [(ngModel)]="wyszukiwanie.filtry.sumaSpisanychSrodkow"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.sumaSpisanychSrodkow"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.sumaSpisanychSrodkow"
          (click)="this.wyszukiwanie.filtry.sumaSpisanychSrodkow = ''"
        ></button>
      </div>
      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Sparowane"
          [(ngModel)]="wyszukiwanie.filtry.sparowaneSrodki"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.sparowaneSrodki"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.sparowaneSrodki"
          (click)="this.wyszukiwanie.filtry.sparowaneSrodki = ''"
        ></button>
      </div>

      <div class="input_container mb-3">
        <input
          class="pr-4"
          type="text"
          placeholder="Utworzył"
          [(ngModel)]="wyszukiwanie.filtry.utworzyl"
        />
        <button
          class="btn__input--search"
          *ngIf="!wyszukiwanie.filtry.utworzyl"
        ></button>
        <button
          class="btn__input--clear"
          *ngIf="wyszukiwanie.filtry.utworzyl"
          (click)="this.wyszukiwanie.filtry.utworzyl = ''"
        ></button>
      </div>
    </div>

    <div
      class="box__mobile box__mobile--form mb-3 py-5 d-flex flex-column"
      *ngIf="czyPokazanoPodmenu"
    >
      <button
        class="btn__badge mb-4 py-2 active"
        (click)="pokazEkranPomieszczen()"
      >
        Pomieszczenia
      </button>
      <button
        class="btn__badge mb-4 py-2"
        (click)="pokazEkranSpisanychSrodkow()"
      >
        Lista spisanych środków
      </button>
      <button class="btn__badge mb-4 py-2" (click)="pokazEkranNadwyzek()">
        Nadwyżki
      </button>
      <button class="btn__badge mb-4 py-2" (click)="pokazEkranNiedoborow()">
        Niedobory
      </button>
      <button
        class="btn__badge mb-4 py-2"
        (click)="pokazEkranSrodkowZOswiadczen()"
      >
        Oświadczenia
      </button>
    </div>

    <button
      class="btn__border mt-4 mb-4"
      (click)="pobierzDaneDoEkranu(true); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazanoWyszukiwarkeMobile"
    >
      Szukaj
    </button>

    <button
      class="btn__clear--filter mt-2 mb-4"
      (click)="wyczyscFiltry(); pokazWyszukiwarkeMobile(false)"
      *ngIf="czyPokazacWyczyscFiltry()"
    >
      Wyczyść filtry
    </button>

    <div
      class="no-results text-center ft__no-results"
      *ngIf="!czySaWynikiNaLiscie(pomieszczenia)"
    >
      <div>Brak pomieszczeń<br />dla wybranych filtrów</div>
    </div>

    <div
      class="box__mobile mb-3"
      *ngFor="let pomieszczenie of pomieszczenia"
      (click)="wybierzPomieszczenie(pomieszczenie.id)"
    >
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Nazwa</span>
        <span class="fs__pname--3 text-lg2">{{ pomieszczenie.nazwa }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Piętro</span>
        <span class="fs__pname--3 text-lg2">{{ pomieszczenie.pietro }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">MPK</span>
        <span class="fs__pname--3 text-lg2">{{ pomieszczenie.mpk }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Opis</span>
        <span class="fs__pname--3 text-lg2">{{ pomieszczenie.opis }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Osoba</span>
        <span class="fs__pname--3 text-lg2">{{ pomieszczenie.osoba }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Inne</span>
        <span class="fs__pname--3 text-lg2">{{ pomieszczenie.inne }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Spisane środki</span>
        <span class="fs__pname--3 text-lg2">{{
          pomieszczenie.sumaSpisanychSrodkow
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Sparowane</span>
        <span class="fs__pname--3 text-lg2">{{
          pomieszczenie.sparowaneSrodki
        }}</span>
      </div>
      <div
        class="d-flex justify-content-between align-items-center pb-2 mr-3 mb-2 m_border"
      >
        <span class="fs__pname--2">Utworzył</span>
        <span class="fs__pname--3 text-lg2">{{ pomieszczenie.utworzyl }}</span>
      </div>

      <div
        class="d-flex justify-content-between align-items-center mr-3 m_border"
      >
        <span
          class="fs__pname--2"
          *ngIf="
            !czyPrzenoszeniePomieszczen ||
            pomieszczenie.czyPomieszczenieOswiadczen
          "
          >&nbsp;</span
        >
        <span
          class="fs__pname--2"
          *ngIf="
            czyPrzenoszeniePomieszczen &&
            !pomieszczenie.czyPomieszczenieOswiadczen
          "
          (click)="$event.stopPropagation()"
        >
          <div class="chb por">
            <input
              id="cb_{{ pomieszczenie.id }}"
              type="checkbox"
              name="{{ pomieszczenie.id }}"
              (change)="pomieszczenieZmiana($event)"
              [(ngModel)]="pomieszczenie.czyWybrano"
            />
            <label
              class="fs__chb mt-2 pr-0 pl-0"
              for="cb_{{ pomieszczenie.id }}"
              >&nbsp;</label
            >
          </div>
        </span>
        <span class="fs__pname--3 text-lg2"
          ><button
            class="btn__edit btn__edit--min mr-2"
            *ngIf="
              !czyProjektZarchiwizowany() &&
              !pomieszczenie.czyPomieszczenieOswiadczen &&
              !czyPracownikKlienta()
            "
            (click)="
              $event.stopPropagation();
              edytujPomieszczenie(pomieszczenie.id) &&
                !pomieszczenie.czyPomieszczenieOswiadczen
            "
          ></button
          ><button class="btn__arrow"></button
        ></span>
      </div>
    </div>

    <div
      class="paginate float-right d-flex align-items-center pb-2 pt-lg-4"
      *ngIf="czySaWynikiNaLiscie(pomieszczenia)"
    >
      <div>Pokaż wyniki</div>
      <select
        class="paginate__select mx-2"
        name=""
        id=""
        (change)="zmienRozmiarTabeli()"
        [(ngModel)]="rozmiarTabeli"
      >
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="100">100</option>
      </select>
      <div class="px-2">
        {{ pobierzNumerRekorduPoczatek() }}-{{ pobierzNumerRekorduKoniec() }} z
        {{ calkowitaLiczbaElementow }}
      </div>
      <div class="d-flex">
        <button
          class="btn__left"
          *ngIf="this.wyszukiwanie.stronicowanie.strona > 1"
          (click)="zmienStrone(-1)"
        ></button>
        <div class="px-2">{{ this.wyszukiwanie.stronicowanie.strona }}</div>
        <button
          class="btn__right"
          *ngIf="czyJestWiecejRekordow"
          (click)="zmienStrone(1)"
        ></button>
      </div>
    </div>
    <button
      class="btn__primary mt-3"
      *ngIf="!czyPrzenoszeniePomieszczen && !czyPracownikKlienta()"
      (click)="wygenerujArkusz()"
    >
      Wygeneruj Arkusz Spisu</button
    ><button
      class="btn__border btn__border--mini px-3 mt-3"
      (click)="dodajPomieszczenie()"
      *ngIf="
        !czyProjektZarchiwizowany() &&
        !czyPrzenoszeniePomieszczen &&
        !czyPracownikKlienta()
      "
    >
      Dodaj pomieszczenie
    </button>
    <button
      class="btn__border btn__border--mini px-3 mt-3"
      (click)="rozpocznijPrzenoszeniePomieszczen()"
      *ngIf="
        !czyProjektZarchiwizowany() &&
        !czyPrzenoszeniePomieszczen &&
        !czyPracownikKlienta()
      "
    >
      Przenieś pomieszczenia
    </button>
    <button
      class="btn__border btn__border--mini px-3 mt-3"
      (click)="przeniesPomieszczenia()"
      *ngIf="czyPrzenoszeniePomieszczen"
    >
      Przenieś
    </button>

    <button
      class="btn__border btn__border--mini px-3 mt-3"
      (click)="zaznaczWszystko()"
      *ngIf="czyPrzenoszeniePomieszczen && !czyZaznaczonoWszystko"
    >
      Zaznacz wszystko
    </button>
    <button
      class="btn__border btn__border--mini px-3 mt-3"
      (click)="odznaczWszystko()"
      *ngIf="czyPrzenoszeniePomieszczen && czyZaznaczonoWszystko"
    >
      Odznacz wszystko
    </button>

    <button
      class="btn__border btn__border--mini px-3 mt-3"
      *ngIf="!czyPrzenoszeniePomieszczen"
      (click)="eksportujDane()"
    >
      Eksportuj dane
    </button>
    <button
      class="btn__link order-1 order-sm-0"
      *ngIf="!czyPrzenoszeniePomieszczen"
      (click)="cofnij()"
    >
      Cofnij
    </button>
    <button
      class="btn__link order-1 order-sm-0"
      *ngIf="czyPrzenoszeniePomieszczen"
      (click)="anulujPrzenoszeniePomieszczen()"
    >
      Anuluj
    </button>
  </div>

  <div class="bootom__buttons px-4 d-none d-md-block">
    <div
      class="flex-column flex-sm-row d-flex justify-content-between align-items-center"
      [ngClass]="
        czyPrzenoszeniePomieszczen || czyPracownikKlienta()
          ? 'container--mw600'
          : 'container--mw1000'
      "
    >
      <button
        class="btn__link order-1 order-sm-0"
        *ngIf="!czyPrzenoszeniePomieszczen"
        (click)="cofnij()"
      >
        Cofnij
      </button>
      <button
        class="btn__link order-1 order-sm-0"
        *ngIf="czyPrzenoszeniePomieszczen"
        (click)="anulujPrzenoszeniePomieszczen()"
      >
        Anuluj
      </button>
      <button
        class="btn__border btn__border--mini px-3"
        *ngIf="!czyPrzenoszeniePomieszczen"
        (click)="eksportujDane()"
      >
        Eksportuj dane
      </button>
      <button
        class="btn__border btn__border--mini px-3"
        (click)="dodajPomieszczenie()"
        *ngIf="
          !czyProjektZarchiwizowany() &&
          !czyPrzenoszeniePomieszczen &&
          !czyPracownikKlienta()
        "
      >
        Dodaj pomieszczenie
      </button>
      <button
        class="btn__border btn__border--mini px-3"
        (click)="rozpocznijPrzenoszeniePomieszczen()"
        *ngIf="
          !czyProjektZarchiwizowany() &&
          !czyPrzenoszeniePomieszczen &&
          !czyPracownikKlienta()
        "
      >
        Przenieś pomieszczenia
      </button>

      <button
        class="btn__border btn__border--mini px-3"
        (click)="zaznaczWszystko()"
        *ngIf="czyPrzenoszeniePomieszczen && !czyZaznaczonoWszystko"
      >
        Zaznacz wszystko
      </button>
      <button
        class="btn__border btn__border--mini px-3"
        (click)="odznaczWszystko()"
        *ngIf="czyPrzenoszeniePomieszczen && czyZaznaczonoWszystko"
      >
        Odznacz wszystko
      </button>

      <button
        class="btn__border btn__border--mini px-3"
        (click)="przeniesPomieszczenia()"
        *ngIf="czyPrzenoszeniePomieszczen"
      >
        Przenieś
      </button>
      <button
        class="btn__blue order-0 order-sm-1"
        *ngIf="!czyPrzenoszeniePomieszczen && !czyPracownikKlienta()"
        (click)="wygenerujArkusz()"
      >
        Wygeneruj Arkusz Spisu
      </button>
    </div>
  </div>
</div>
