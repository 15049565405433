import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BazowyComponent } from 'src/app/komponenty/bazowy.component';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';

@Component({
  selector: 'app-znalezione-rekordy-ewidencji-modal',
  templateUrl: './znalezione-rekordy-ewidencji-modal.component.html',
  styleUrls: ['./znalezione-rekordy-ewidencji-modal.component.css'],
})
export class ZnalezioneRekordyEwidencjiModalComponent
  extends BazowyComponent
  implements OnInit
{
  listaKolumn: Array<string>;
  dane: Array<Array<string>>;
  kolumnaId: number;
  wybranyRekordId: string;

  constructor(
    public router: Router,
    modalService: NgbModal,
    loaderSerwis: LoaderService,
    public activeModal: NgbActiveModal,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.kolumnaId = this.listaKolumn.indexOf('id');
    });
  }
  wybierzRekord(indeks: number) {
    var nowy = this.dane[indeks][this.kolumnaId];
    if (this.wybranyRekordId == nowy) {
      this.wybranyRekordId = undefined;
      return;
    }
    this.wybranyRekordId = nowy;
  }

  rekordZaznaczony(indeks: number) {
    return this.wybranyRekordId == this.dane[indeks][this.kolumnaId];
  }

  anuluj() {
    this.activeModal.dismiss();
  }

  potwierdz() {
    if (!this.wybranyRekordId) {
      return;
    }
    this.activeModal.close(this.wybranyRekordId);
  }
}
