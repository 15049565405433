<div class="modal-content">
  <div class="modal-body">
    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="activeModal.dismiss()"
    ></button>
    <div class="text-center mb-2"></div>

    <div class="fixed_table_container">
      <table>
        <tr>
          <th
            *ngFor="let kolumna of listaKolumn; index as i"
            [ngClass]="i == kolumnaId ? 'id' : ''"
          >
            {{ kolumna }}
          </th>
        </tr>
        <tr
          *ngFor="let wiersz of dane; index as r"
          class="pointer project-row"
          (click)="wybierzRekord(r)"
          [ngClass]="rekordZaznaczony(r) ? 'selected' : ''"
        >
          <td
            *ngFor="let wartosc of wiersz; index as i"
            [ngClass]="i == kolumnaId ? 'id' : ''"
          >
            {{ wartosc }}
          </td>
        </tr>
      </table>
    </div>

    <div class="row pb-3">
      <div class="col-6">
        <div class="input_container pb-3 pt-2">
          <button class="btn__link" (click)="anuluj()">Anuluj</button>
        </div>
      </div>
      <div class="col-6">
        <div class="input_container pb-3 pt-2">
          <button class="btn__link" (click)="potwierdz()">Potwierdź</button>
        </div>
      </div>
    </div>
  </div>
</div>
