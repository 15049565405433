import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BazowyComponent } from '../../bazowy.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/serwisy/ogolne/auth.service';
import { LoaderService } from 'src/app/serwisy/ogolne/loader.service';
import { SlownikiService } from 'src/app/serwisy/projekty/slowniki.service';
import { ProjektyService } from 'src/app/serwisy/projekty/projekty.service';
import { KopiujProjektModel } from 'src/app/modele/projekty/kopiujProjekt/kopiujProjektModel';
import { Routing } from 'src/app/modele/wspolne/routing';
import { DostepnyElementDoSkopiowania } from 'src/app/modele/projekty/kopiujProjekt/pobierzDaneDoKopiowaniaProjektuRezultat';

@Component({
  selector: 'app-kopiuj-projekt-modal',
  templateUrl: './kopiuj-projekt-modal.component.html',
  styleUrls: ['./kopiuj-projekt-modal.component.css'],
})
export class KopiujProjektModalComponent
  extends BazowyComponent
  implements OnInit, OnChanges
{
  idProjektu: string;
  nazwaProjektu: string;
  model: KopiujProjektModel = new KopiujProjektModel();
  zaladowanoDane: boolean;
  dostepneElementy: Array<DostepnyElementDoSkopiowania> = [];

  constructor(
    public router: Router,
    modalService: NgbModal,
    public activeModal: NgbActiveModal,
    loaderSerwis: LoaderService,
    komunikatSerwis: ToastrService,
    public authService: AuthService,
    public projektySerwis: ProjektyService,
    public slownikiSerwis: SlownikiService,
    public translateSerwis: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(
      authService,
      modalService,
      loaderSerwis,
      komunikatSerwis,
      router,
      translateSerwis
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.obsluzZmianeWybranychElementow();
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.model.idProjektu = this.idProjektu;
      this.pobierzDane();
    });
  }

  pobierzDane() {
    this.projektySerwis
      .pobierzDaneDoKopiowaniaProjektu(this.idProjektu)
      .subscribe((rezultat) => {
        this.zaladowanoDane = true;
        if (this.czyRezultatOk(rezultat)) {
          this.nazwaProjektu = rezultat.element.nazwaProjektu;
          this.model.nazwaProjektu = rezultat.element.nazwaProjektu;
          this.model.nazwaKlienta = rezultat.element.nazwaKlienta;
          this.model.email = rezultat.element.email;

          this.dostepneElementy = rezultat.element.dostepneElementy;
          return;
        }
        this.wyswietlKomunikat(rezultat.wiadomosc, true);
        return;
      });
  }

  zapisz() {
    this.projektySerwis.kopiujProjekt(this.model).subscribe((rezultat) => {
      if (this.czyRezultatOk(rezultat)) {
        this.wyswietlKomunikat('Projekt został skopiowany');
        this.activeModal.close({ rezultat: true, id: rezultat.element.id });

        return;
      }
      if (this.bladModelu(rezultat)) {
        this.bledyWalidacji = rezultat.bledyModelu;
        return;
      }

      this.wyswietlKomunikat(rezultat.wiadomosc, true);
      return;
    });
  }

  get aktualneElementy() {
    return [
      this.model.lokalizacje == true ? 'lokalizacje' : '',
      this.model.ewidencja == true ? 'ewidencja' : '',
      this.model.komentarzeWEwidencji == true ? 'komentarzeWEwidencji' : '',
      this.model.slowniki == true ? 'slowniki' : '',
      this.model.zespoly == true ? 'zespoly' : '',
      this.model.polaSpisu == true ? 'polaSpisu' : '',
      this.model.polaPomieszczen == true ? 'polaPomieszczen' : '',
      this.model.mapowania == true ? 'mapowania' : '',
    ].filter((m) => m);
  }

  czyElementDostepny(element: string) {
    var konfiguracja = this.dostepneElementy.find((m) => m.element == element);
    if (konfiguracja.dostepny == 'dostepny') {
      return true;
    }
    if (konfiguracja.dostepny == 'niedostepny') {
      return false;
    }
    if (konfiguracja.dostepny == 'warunkowo') {
      var warunkoweElementy = konfiguracja.elementWarunkowy.split(',');
      if (warunkoweElementy.every((m) => this.aktualneElementy.includes(m))) {
        return true;
      }

      return false;
    }
  }

  onCheckboxChange(event: Event, element: string): void {
    const input = event.target as HTMLInputElement;
    this.obsluzZmianeWybranychElementow();
  }

  obsluzZmianeWybranychElementow() {
    this.dostepneElementy
      .filter((m) => m.dostepny == 'warunkowo')
      .forEach((m) => {
        var warunkoweElementy = m.elementWarunkowy.split(',');
        if (
          !warunkoweElementy.every((m) => this.aktualneElementy.includes(m))
        ) {
          this.zmienAktualneElementy(m.element, false);
        }
      });
  }

  zmienAktualneElementy(element: string, wartosc: boolean) {
    switch (element) {
      case 'lokalizacje':
        this.model.lokalizacje = wartosc;
        break;
      case 'ewidencja':
        this.model.ewidencja = wartosc;
        break;
      case 'komentarzeWEwidencji':
        this.model.komentarzeWEwidencji = wartosc;
        break;
      case 'slowniki':
        this.model.slowniki = wartosc;
        break;
      case 'zespoly':
        this.model.zespoly = wartosc;
        break;
      case 'polaSpisu':
        this.model.polaSpisu = wartosc;
        break;
      case 'polaPomieszczen':
        this.model.polaPomieszczen = wartosc;
        break;
      case 'mapowania':
        this.model.mapowania = wartosc;
        break;
    }
  }
}
